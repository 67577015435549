.app-shell {
    padding: 0;
    margin: 0;
}

.text-ellipsis {
    max-width: inherit !important;
}

td.text-ellipsis {
    max-width: 1px !important;
}

.badge {
    border-radius: 0;
}
a {
    &,
    &:focus,
    &:hover {
        text-decoration: none;
    }

    &[disabled] {
        cursor: default;
    }
}

//
// Reset normalize html
// --------------------------------------------------
& {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: sans-serif; // 1
    -ms-text-size-adjust: 100%; // 2
    -webkit-text-size-adjust: 100%; // 2

    input[type='button'] {
        -webkit-appearance: button; // 2
        cursor: pointer; // 3
    }

    input[disabled] {
        cursor: default;
    }
}
