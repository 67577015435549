#id-apps {
  /* ellipsis automatique */
  table.table-ellipsis {
    tbody {
      > tr {
        > td {
          vertical-align: middle;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 1px;

          .tooltip {
            white-space: normal;
          }
        }

        > td.no-ellipsis {
          text-overflow: inherit;
          white-space: inherit;
          overflow: inherit;
          max-width: inherit;
        }
      }
    }
  }

  /* helpers divers */
  .inactive-row {
    background-color: #ECECEC;
    color: grey;
  }
}