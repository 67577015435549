massia-slider {
    $size-pointer: 15px;
    $size-container: 5px;
    .slider-container {
        border-radius: 25px;
        height: $size-container;
        margin: 0;
        position: relative;

        &.noanimate {
            transition: none;
        }

        .slider-wrapper {
            height: 100%;
            padding: 0;
            margin-top: 0;
            overflow: hidden;
            transition: width 0.2s;
            .slider-bar {
                background: #ccc;
                height: 100%;
                overflow: hidden;
                //border-radius: 25px;

                &.rz-selection {
                    background-color: $primary;
                }
            }
        }

        .slider-pointer {
            transition: all 0.2s;
            padding: 2px;
            width: $size-pointer;
            height: $size-pointer;
            top: 50%;
            transform: translateY(-50%);
            outline: none;
            border: 2px solid #ccc;
            background: white;
            &.pointer-active {
                border: none;
                background: $primary;
            }

            &:focus {
                width: $size-pointer * 1.2;
                height: $size-pointer * 1.2;
            }

            &::after {
                display: none;
            }
        }

        /* .slider-bubble {
            position: relative;
        } */
    }
}
