//
// Pageslide
// --------------------------------------------------
[ac-pageslide] {
  .btn-slider-close {
    color: #fff;
  }
  .slider-heading {
    @extend .bg-primary;
  }
}

//
// Header
// --------------------------------------------------
.massia-header {
  background-image: url($logo-url);

  user-info {
    .btn-link {
      color: $primary;

      &:hover {
        color: $primary-dark;
      }
    }
  }
}

//
// Navigation
// --------------------------------------------------
.navbar {
  .navbar-search .form-control:focus {
    border-color: #fff;
  }
}

.navbar-default {
  input,
  .ui-select-toggle {
    border-color: $navbar-default-color;
    color: $navbar-default-color;
    background-color: transparent !important;
  }

  .btn-default-focus {
    background-color: $primary-dark;
  }

  @media (max-width: #{$screen-xs-max}) {
    .dropdown-header-collapse:hover {
      background-color: $primary-dark;
    }
  }

  @media (min-width: #{$screen-sm-min}) {
    .menu-item-separator {
      border-bottom: 1px solid $gray-light;
    }

    .dropdown-header {
      background-color: $gray-lighter;
      border-bottom: 1px solid $gray-light;
      border-top: 1px solid $gray-light;
    }
  }
}

//
// Treeview
// --------------------------------------------------
[ac-treeview] {
  .node-selected {
    background: $gray-lighter;
    border-color: $gray-light;
  }

  .ac-treeview-root-list ol ol {
    border-color: $gray-light;
  }

  .node-folder-icon .glyphicon,
  .node-root-icon.glyphicon{
    color: $primary;
  }
}
