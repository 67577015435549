.table {
    z-index: 1;
    position: relative;
    td {
        .dropdown-menu li>a {
            padding-left: 3px;

            .glyphicon {
                padding-right: 5px;
            }
        }

        &.row-odd {
            background-color: $table-bg-accent;
        }

        &.row-even {
            background-color: #f4f4f4;
        }
    }

    >tbody>tr>td,
    >tbody>tr>th,
    >tfoot>tr>td,
    >tfoot>tr>th,
    >thead>tr>td,
    >thead>tr>th {
        border-top: 0;
    }

    >thead>tr>th {
        border-bottom: 0;
        border-top: 1px solid;
        font-weight: normal;

        +th {
            border-left: 1px solid;
        }
    }

    tfoot {
        border-top: 1px solid;

        >tr>td {
            padding: 15px 0 0 0;
        }
    }

    .pagination {
        &-wrapper {
            text-align: right;
            padding: 0 10px;
            height: 34px;

            b {
                font-weight: normal;
            }

            .pull-right {
                float: left !important;
                margin-top: 4px;
                margin-left: 10px;
            }
        }

        >li {
            display: inline-block;

            &:nth-child(n + 2) {
                margin-left: -5px;
            }
        }
    }

    td.table-form {
        padding: 2px 0;
    }

    .st-sort {
        &:hover:before {
            opacity: 1;
        }
        &:before {
            top: 3px;
            padding-top: 0;
            opacity: 0.5;
            float: right;
            font-weight: bold;
            -ms-transform: none; /* IE 9 */
            -webkit-transform: none; /* Chrome, Safari, Opera */
            transform: none;

            @extend .glyphicon;
            @extend .glyphicon-chevron-right;
        }

        &.st-sort-ascent:before {
            @extend .glyphicon-chevron-up;
        }

        &.st-sort-descent:before {
            @extend .glyphicon-chevron-down;
        }
    }

    .slim-header th {
        padding: 0 4px;
    }

    .add-line {
        background-color: #cccccc !important;

        td {
            padding: 5px !important;
        }
    }

    tr.errors {
        color: #a94442 !important;
        background-color: #f2dede !important;
        border-color: #ebccd1 !important;
    }
}