@keyframes slide-from-top {
    0% {
        transform: translateY(-50%);
        opacity: 0;
    }
    50% {
        transform: translateY(5%);
        opacity: 1;
    }
    75% {
        transform: translateY(-5%);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes expand-modal {
    0% {
        transform: translateX(-100%);
        max-height: 0px;
        opacity: 0;
    }
    100% {
        max-height: 2000px;
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes bouncingdot {
    0% {
        transform: translateY(-50%);
    }
    25% {
        transform: translateY(-50%);
    }
    50% {
        transform: translateY(-80%);
    }
    75% {
        transform: translateY(-50%);
    }
    100% {
        transform: translateY(-50%);
    }
}
