[uib-modal-window] {
  .modal-content {
    .modal-header {
      border-bottom: 0;
    }

    .modal-body > .form-horizontal {
      margin-top: $form-group-margin-bottom;
    }
  }
}

#conditionDeVenteModalContent {
  .modal-content {
    background-color: #f4f4f4 !important;
  }
  
  .modal-footer {
    background-color: #ffffff;
  }
}