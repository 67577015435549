#app-header {
  font-family: Helvetica, Arial, sans-serif;
  margin-bottom: 6px;
  line-height: 18px;
}

.arcade-title {
  font-family: $CenturyFont;
  letter-spacing: 1px;
}

.massia-header {

  $userInfoWidth: 300px;

  height: 75px;
  background-color: white;
  background-repeat: no-repeat;
  background-size: 130px auto;
  background-position: center center;

  @media (max-width: $screen-xs-max) {
    background-position-x: 15px;
  }

  @media (min-width: $screen-xs-max) {
    background-position-x: center;
  }


  logo {
    background: url(../img/LogoArcade_128x36.png) no-repeat 15px center;
    display: inline-block;
    width: calc(100% - #{$userInfoWidth} - 5px);
    height: 100%;

    .arcade-title {
      font-size: 1.2em;
      margin: 33px 0 0 170px;
    }

    &.logo-right{
      display: none;
      position: absolute;
      right: 2px;
      width: 160px;
      height: 45px;
      z-index: 2;
      
      h3{
        display: none;
      }
    }
  }


  user-info {
    float: right;
    width: $userInfoWidth;

    .user-logo {
      padding-top: $padding-base-vertical;
      padding-bottom: $padding-base-vertical;
    }

    .btn-link {
      padding-top: $padding-base-vertical;
      text-decoration: none;
      padding-left: $padding-base-horizontal;
    }
  }
}