@keyframes spin {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

.ac-loading-double-spinner {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    padding: 0.5em;
    vertical-align: bottom;
    text-align: center;
    background-color: transparent;
    border-style: solid;
    border-color: transparent;
    border-width: 5px;
    border-top-color: #c22;
    border-bottom-color: #c22;
    border-radius: 50%;
}

.ac-loading-double-spinner.outer {
    animation: spin 1s infinite;
}

.ac-loading-double-spinner.inner {
    animation: spin 1s infinite;
}