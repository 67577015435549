#id-apps {
  /****************
  ** ac-checkbox
  ****************/
  .ac-checkbox-disabled {
    color: #ccc;
  }
  /****************
  ** colorpicker
  ****************/
  .colorpicker {
    .close-colorpicker {
      display: none;
    }
  }
  /****************
  ** ac-stackedbar
  ****************/
  .ac-stackedbar {
    height: 50px;
    width: 100%;
    background: #999;
  }

  .ac-stackedbar section {
    height: 50px;
    float: left;
  }
  /****************
  ** multiselect
  ****************/
  .pointer-events-none .multiSelect button {
    color: #eee;
  }

  .pointer-events-none.ui-select-multiple,
  .pointer-events-none .multiSelect button,
  div.pointer-events-none .ui-select-match .ui-select-toggle {
    //background-color: #eee;
    opacity: 1;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  }

  div.pointer-events-none input.ui-select-search {
    pointer-events: none !important;
  }
}