@mixin create-mediaquery($breakpoint, $mqsize) {
    @if ($breakpoint == 0) {
        @content;
    } @else {
        @media screen and (#{$mqsize}-width: $breakpoint * 1px) {
            @content;
        }
    }
}

@mixin create-col-classes($modifier, $cols-size, $breakpoint) {
    @include create-mediaquery($breakpoint, 'min') {
        @for $i from 1 through $cols-size {
            .mas-col#{$modifier}-#{$i} {
                flex-basis: calc(100 / calc($col-size / $i)) * 1%;
                max-width: calc(100 / calc($col-size / $i)) * 1%;
            }
        }
    }
}

@mixin create-hidden-classes($modifier, $breakpoint) {
    @if ($modifier == '') {
        $modifier: '-xs';
    }

    @include create-mediaquery($breakpoint - 1, 'max') {
        .mas-hidden#{$modifier} {
            display: none !important;
        }
    }

    @include create-mediaquery($breakpoint, 'min') {
        .mas-hidden#{$modifier}-up {
            display: none !important;
        }
    }
}

@mixin stagger($time, $max) {
    @for $i from 1 through $max {
        &:nth-child(#{$i}) {
            animation-delay: $time * $i;
        }
    }
}
