massia-table {
  .panel-body {
    overflow: auto;

    .table {
      min-width: 750px;
    }
  }

  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    height: 5px;
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: color of position indicator */
  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: $brand-primary;
  }
}
