/* UI Grid Group Columns */
.ui-custom-grid {
    //height: auto !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

[ui-grid-group-columns] {
    // Elements with `display: table;` do not behave well when used directly as flex items.
    // https://stackoverflow.com/questions/35936383/display-table-on-flex-item
    .ui-grid-header-canvas {
        display: flex;
    }

    .ui-grid-cell-auto-fit {
        max-width: max-content !important;
        min-width: max-content !important;
    }

    // The group container's width should adapt as columns are resized.
    // The group label should not influence the width of the group container,
    // so we remove it from the normal flow.
    .ui-grid-header-group-name {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 31px;
        border-bottom: solid 1px #d4d4d4;
        border-right: solid 1px #d4d4d4;
        box-sizing: border-box;
        text-align: center;
    }

    .ui-grid-header-group {
        &.named {
            position: relative;
            padding-top: 31px;
        }

        &:not(:last-child) {
            .ui-grid-header-group-name,
            .ui-grid-header-cell:last-child {
                border-right-width: 3px;
            }
        }
    }

    // Ensure correct position of `ui-grid-column-menu` in Firefox ESR
    .ui-grid-header-cell-wrapper,
    .ui-grid-header-cell-row {
        position: static;
    }

    .ui-grid-header-cell {
        height: 100%;
        vertical-align: middle;

        [role='columnheader'] {
            position: relative;
        }
    }

    .ui-grid-header-cell:last-child {
        border-right: solid 1px #d4d4d4;

        .ui-grid-column-resizer.right {
            border-right: none;
        }
    }

    .ui-grid-cell.last-in-group:not(:last-child) {
        border-right-width: 3px;
    }

    .ui-grid-footer-cell:last-child {
        border-right: solid 1px #d4d4d4;
    }

    .ui-grid-footer-cell.last-in-group:not(:last-child) {
        border-right-width: 3px;
    }

    .ui-grid-pinned-container.ui-grid-pinned-container-left {
        .ui-grid-header-group:last-child {
            .ui-grid-header-group-name,
            .ui-grid-header-cell:last-child {
                border-right: solid 3px #aeaeae;
            }
        }

        .ui-grid-header-cell:last-child {
            border-right-color: #d4d4d4;
        }

        .ui-grid-cell:last-child {
            border-right-width: 3px;
        }

        .ui-grid-footer-cell:last-child {
            border-right: solid 3px #aeaeae;
        }
    }

    .ui-grid-pinned-container.ui-grid-pinned-container-right {
        .ui-grid-header-group:first-child {
            .ui-grid-header-group-name,
            .ui-grid-header-cell:first-child {
                border-left: solid 3px #aeaeae;
            }
        }

        .ui-grid-header-cell:first-child {
            border-left: none;
        }

        // Added again here in order to overcome specificity issues with the default UI Grid CSS
        .ui-grid-cell.last-in-group:not(:last-child) {
            border-right-width: 3px;
        }

        .ui-grid-cell:first-child {
            border-left-width: 3px;
        }

        .ui-grid-footer-cell:first-child {
            border-left: solid 3px #aeaeae;
        }
    }
}
