/****************
** VALIDATION
****************/
.validation-sign {
  color: #a94442;
  font-size: 1.4em;
  line-height: 30px;
}

.has-error label {
  color: #a94442;
}

.header-error {
  background: red !important;
  color: black !important;
}

.small-validation {
  color: red;
  font-weight: bold;
}

.has-error .btn-calendar {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.ac-validation-hidden {
  .validation-message {
    display: none;
  }
}

.validation-message .popover {
  min-width: 225px;
  text-align: center;
  width: auto;
}

.in-error.form-control {
  border: solid 2px !important;
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.display-validation.input-group[class*="col-"] {
  float: left;
}

input.ng-invalid:not(.ng-invalid-parse) {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

input.ng-invalid:not(.ng-invalid-parse):focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}

input.ng-invalid:not(.ng-invalid-parse) ~ span button,
input.ng-invalid:not(.ng-invalid-parse) ~ span button:hover,
input.ng-invalid:not(.ng-invalid-parse) ~ span button:focus {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}