#id-apps {
    /****************
  ** MODAL
  ****************/
    .modal-body > p {
        word-wrap: break-word;
    }

    h4.modal-title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        .glyphicon {
            top: 0;
            line-height: inherit;
            vertical-align: middle;

            + span {
                margin-left: 8px;
                vertical-align: middle;
            }
        }
    }

    .modal-success {
        background-color: #dff0d8;
        border-bottom: 2px solid #4cae4c;
    }

    .modal-success h4,
    .modal-success .close {
        color: #3c763d;
    }

    .modal-info {
        background-color: #d9edf7;
        border-bottom: 2px solid #46b8da;
    }

    .modal-info h4,
    .modal-info .close {
        color: #31708f;
    }

    .modal-warning {
        background-color: #fcf8e3;
        border-bottom: 2px solid #eea236;
    }

    .modal-warning h4,
    .modal-warning .close {
        color: #8a6d3b;
    }

    .modal-danger {
        background-color: #f2dede;
        border-bottom: 2px solid #d43f3a;
    }

    .modal-danger h4,
    .modal-danger .close {
        color: #a94442;
    }
    .modal-xl {
        width: 1000px;
    }
    .modal-xxl {
        width: 1300px !important;
        max-width: 90vw !important
    }
    .modal-xxxl {
        width: 1400px;
    }
}
