.dropdown-align-right {
  > li {
    text-align: right;

    > a {
      span + .glyphicon {
        margin-left: calc($padding-base-horizontal / 2);
      }
    }
  }
}