@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ac-loading-spinner {
    border-radius: 50%;
    display: inline-block;
    border: 0.25rem solid;
    border-color: rgba(255, 255, 255, 0.5);
    border-top-color: currentColor !important;
    animation: spin 1s infinite linear;
    height: 20px;
    width: 20px;
}