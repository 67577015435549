massia-print-modal {
  .modal-header {
    //background: $brand-primary;
    //color: white;
  }
  .pdf-checker {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  .print-modal-label {
    margin-right: 10px;
  }

  .input-pdf {
    margin: 0;
    float: none;
    margin-left: 10px;
  }

  .label-pdf {
    margin: 0;
    vertical-align: text-bottom;
  }
}
