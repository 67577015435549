/****************
** PAGINATION
****************/
.pagination-wrapper {
  height: 34px;

  input {
    height: 34px;
    padding: 3px 6px;
    width: 60px;
  }

  button {
    color: #CD1041;
    padding: 3px 6px;
  }

  form button {
    width: auto;
  }

  form label {
    line-height: 34px;
    padding-left: 0;
    padding-right: 0;
    vertical-align: top;
  }

  .pagination {
    margin: 0;

    .btn-square {
      color: #CD1041;
      padding: 3px 10px;
    }

    .btn-default[disabled] {
      opacity: 0.5;
    }

    .active button,
    .btn-default:hover {
      font-weight: bold;
      background-color: #CD1041;
      border-color: #CD1041;
      color: #fff;
    }
  }

  .pagination-current-page,
  .pagination-current-page:hover {
    font-weight: bold;
    cursor: default;
    pointer-events: none;
    min-width: 38px;
    text-align: center;
  }
}

.table-pagination-outside {
  position: relative;
  margin-bottom: 40px;

  > tfoot {
    td[st-pagination] {
      position: absolute;
      bottom: -40px;
      width: 100%;
      padding: 0;
      border-top: none;
    }
  }
}