#id-apps {
    // Variables
    @import 'variables';
    @import '../../libs/massia-component/massia-style/variable';
    // Libs
    @import '~angular-ui-bootstrap/dist/ui-bootstrap-csp';
    @import 'leaflet'; ///"~leaflet/dist/leaflet";
    // Bootstrap
    @import 'bootstrap/variables';
    @import 'bootstrap/mixins';
    @import 'bootstrap/normalize';
    @import 'bootstrap/print';
    @import 'bootstrap/glyphicons';
    @import 'bootstrap/scaffolding';
    @import 'bootstrap/type';
    @import 'bootstrap/code';
    @import 'bootstrap/grid';
    @import 'bootstrap/tables';
    @import 'bootstrap/forms';
    @import 'bootstrap/buttons';
    @import 'bootstrap/component-animations';
    @import 'bootstrap/dropdowns';
    @import 'bootstrap/button-groups';
    @import 'bootstrap/input-groups';
    @import 'bootstrap/navs';
    @import 'bootstrap/navbar';
    @import 'bootstrap/breadcrumbs';
    @import 'bootstrap/pagination';
    @import 'bootstrap/pager';
    @import 'bootstrap/labels';
    @import 'bootstrap/badges';
    @import 'bootstrap/jumbotron';
    @import 'bootstrap/thumbnails';
    @import 'bootstrap/alerts';
    @import 'bootstrap/progress-bars';
    @import 'bootstrap/media';
    @import 'bootstrap/list-group';
    @import 'bootstrap/panels';
    @import 'bootstrap/responsive-embed';
    @import 'bootstrap/wells';
    @import 'bootstrap/close';
    @import 'bootstrap/modals';
    @import 'bootstrap/tooltip';
    @import 'bootstrap/popovers';
    @import 'bootstrap/carousel';
    @import 'bootstrap/utilities';
    @import 'bootstrap/responsive-utilities';
    // Layout
    @import 'components/reset';
    @import 'components/accordion';
    @import 'components/button';
    @import 'components/dropdown';
    @import 'components/form';
    @import 'components/modal';
    @import 'components/table';
    @import 'components/panel';
    @import 'components/tooltip';
    // Components
    @import 'modules/layout/header';
    @import 'modules/layout/main';
    @import 'modules/layout/footer';
    @import 'modules/layout/breadcrumb';
    @import 'modules/layout/navigation';
    @import 'modules/treeview';
    @import 'modules/login';
    @import 'modules/pageslide';
    @import 'modules/hierarchie';
    @import 'modules/ui.select';
    // Theme
    @import 'theme/components';
    @import 'theme/modules';

    @import '~angular-ui-tree/dist/angular-ui-tree.min.css';
    @import '~angular-ui-grid/ui-grid.min.css';

    $border-px: 2px;
    $border-size-px: $border-px solid black;

    .grey {
        filter: grayscale(1);
    }

    .mas-fade {
        &.ng-enter {
            transition: all 0.25s;
            opacity: 0;
        }

        &.ng-enter-active {
            opacity: 1;
        }
    }

    .mas-fade.ng-hide {
        opacity: 0;
    }

    .ng-hide {
        opacity: 0;
        transition: none 0;
    }

    hr {
        margin: 10px;
    }

    .disable-panel-margin {
        .panel-collapse {
            margin: 0 !important;
            padding: 0 !important;
        }

        .panel-body {
            margin: 0;
            padding: 0;
        }
    }

    .synthese-page {
        overflow: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 55px;
        transition: all 0.3s ease-in-out;
        transform: translateX(0);

        &.ng-hide {
            transition: all 0.3s;
            transform: translateX(100%);
            opacity: 0;
        }
    }

    .btn-rounded {
        border-radius: 3px;
    }

    .btn:focus {
        outline: none;
    }

    .btn-circle {
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 1px;
        font-size: 21px;
        border-radius: 50%;
    }

    .btn-circle.btn-small
    {
        height: 20px !important;
        width: 20px !important;
        font-size: 13px !important;
    }

    table,
    th {
        &.table-border {
            border: 1px solid white;
        }
    }

    .table-striped {
        tbody {
            tr {
                &:nth-of-type(odd) {
                    .td-bg {
                        background-color: $table-bg-accent;
                    }

                    background-color: $table-bg-accent;
                }

                &:nth-of-type(even) {
                    .td-bg {
                        background-color: $table-bg;
                    }

                    background-color: $table-bg;
                }

                &.transport-price-grid-row td {
                    width: 1%;
                    white-space:nowrap;
                }

                &.draggable {
                    :hover {
                        cursor: grab;
                    }
                    :active {
                        cursor: grabbing;
                    }
                }
                td {
                    div.max-lines {
                        max-height: 60px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    @each $key,
    $color in $colors {
        .bg-#{"" + $key} {
            background-color: $color;
        }
    }

    .fake-input {
        background-color: #f0f8ff;
        text-overflow: ellipsis;
        border: 1px solid #b7b7b7;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        display: block;
        padding: 5px 5px 5px 10px;
        height: 34px;
        width: 100%;
    }

    .overide-st-sort {
        &.st-sort {
            &::after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 5px;
            }
        }
    }

    .bold {
        font-weight: bold;
    }

    .modal-overflow {
        max-height: 20%;
        overflow: auto;
    }

    .modal-fit {
        .modal-dialog {
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            padding: 10px;
        }

        .modal-body {
            max-height: calc(100vh - 200px);
            overflow: auto !important;
        }
    }

    .main-container {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .main-content {
        overflow: auto;
        flex-grow: 1;
        position: relative;
        margin-bottom: $footer-size;
    }

    .bounceSynth {
        &.ng-leave.ng-leave-active {
            transform: scale(0);
            transition: all linear 150ms;
        }

        &.ng-enter,
        &.ng-move {
            transform: scale(0);
            transition: all linear 150ms;
        }

        &.ng-enter-stagger,
        &.ng-leave-stagger,
        &.ng-move-stagger {
            transition-delay: 25ms;
            transition-duration: 200ms;
        }

        &.ng-enter.ng-enter-active,
        &.ng-leave {
            transform: scale(1);
            transition: all linear 150ms;
        }
    }

    .hover-danger {
        transition: all 0.2s;

        &:hover {
            color: red;
        }
    }

    .bg-disabled {
        .panel-heading {
            background-color: #b7b7b7 !important;
            background: #b7b7b7 !important;
        }

        .text-muted {
            color: #484848;
        }
    }

    .bg-success {
        border: 2px solid $brand-success  !important;
    }

    .bg-danger {
        border: 2px solid $brand-danger  !important;
    }

    .card {
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        width: 100%;
        padding: 15px;
        //margin: 5px;
        //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        /* transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    &:hover {
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    } */
    }

    .product {
        transform-origin: 0% 50%;
        transform: scaleX(1);
        transition: all 0.2s;

        &.ng-hide {
            transform: scaleX(0);
        }
    }

    .card {
        .form-control {
            background-color: #f0f8ff !important;
        }
    }

    .card>.form-group {
        margin: 0;
        margin-bottom: 6px;

    }

    label.print-modal-label {
        margin-right: 10px !important;
    }

    .pcr-interaction {
        input {
            margin-top: 0.75em;
            margin-left: 0.2em;
            margin-right: 0.2em;
        }
    }

    .btn-modal {
        border-radius: 0px;
        transition: all 0.2s;
    }

    .modal-content {
        border-radius: 0px;
    }

    .file-information-close {
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        height: 0;
    }

    .file-information-open {
        animation: infoOpen 0.4s;
        margin-top: 10px;
        padding: 10px;
    }

    .file-info-container {
        margin-bottom: 20px;
    }

    .btn-upload {
        width: 100% !important;
        position: absolute;
        margin: 0 !important;
        transform: scaleY(0);
        top: 0;
        left: 0;
        transition: 0.3s;
        overflow: hidden;
    }

    @keyframes infoOpen {
        from {
            margin-top: 0px;
            height: 0;
            padding: 0px;
        }

        to {
            margin-top: 10px;
            height: 100%;
            padding: 10px;
        }
    }

    button {
        &.visible {
            transform: scaleY(1);
            transition: all 0.3s;
        }
    }

    .loading-open {
        animation: loadingOpen 0.3s;
        height: 100%;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .loading-close {
        height: 0;
        width: 0;
        margin-bottom: 0px;
        transition: all 0.3s;
    }

    .alert-close {
        transform: scaleY(0);
        margin: -20px;
        height: 0;
        transition: all 0.2s;
    }

    .alert-open {
        margin: 0;
        height: auto;
        transform: scaleY(1);
        transition: all 0.2s;
    }

    @keyframes loadingOpen {
        from {
            height: 0;
            width: 0;
        }

        to {
            height: 100%;
            width: 100%;
            margin-top: 20px;
        }
    }

    .file-input {
        height: 0;
        width: 0;
    }

    .select-excel {
        padding: 1px !important;
        width: 100%;
    }

    produit-form-commerciaux {
        @keyframes slide {
            0% {
                transform: scaleX(0);
            }

            100% {
                transform: scaleX(1);
            }
        }

        .slide-form {
            transform-origin: 0% 50%;
            animation: slide 0.2s;
            transition: opacity 0.1s;

            &.ng-hide {
                opacity: 0;
            }
        }
    }

    @media screen and (max-width: 768px) {
        massia-navigation {
            .navbar-collapse.collapse.in {
                max-height: calc(100vh - 75px);
                overflow: scroll;
            }

            .glyphicon-chevron-right {
                opacity: 0.5;
                transition: all 0.3s;
                transform: rotate(90deg);
            }

            .rotate {
                transform: rotate(-90deg);
                opacity: 1;
                transition: all 0.3s;
            }

            .mobile-open>ul {
                height: 100%;
                transition: height 0.2s;
            }

            .mobile-close>ul {
                height: 0px;
                overflow: hidden;
                transition: height 0.2s;
            }

            .sub-menu-header {
                position: relative;

                span {
                    color: #4c4c4c;
                }

                .sub-menu-nav {
                    list-style: none;
                    padding: 0;

                    .sub-menu-nav-item {
                        padding: 8px 10px;

                        a {
                            color: white;
                            display: block;
                            clear: both;
                            font-weight: 400;
                            line-height: 1.42857143;
                            white-space: nowrap;
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
    }

    .synthese-row {
        transform-origin: 50% 0%;
        transform: scaleY(1);
        transition: all 150ms;
        max-height: 10000000000000000px;

        &.ng-hide {
            transform: scaleY(0);
            max-height: 0;
        }
    }

    .table-fixed-header {
        border-collapse: separate;

        thead {
            tr {
                th {
                    position: sticky;
                    top: 0px;
                    z-index: 15;
                }
            }
        }
    }

    .table-fixed-row {
        position: sticky;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.4);
        border-top: 1px solid rgba($color: #000000, $alpha: 0.4);
        top: 0;
        z-index: 16;
        background-color: #fff;
    }

    .table-fixed-column {
        position: sticky;
        left: 0;
        background-color: #fff;
    }

    .table-scrollable {

        thead,
        tbody tr {
            table-layout: fixed;
            width: 100%;
            display: table;
        }

        tbody {
            display: block;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .flex {
        display: flex;
    }

    .period-switch.ng-animate {
        transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
        position: absolute;
        top: 0;
        left: 10px;
        right: 0;
        bottom: 0;
    }

    .period-switch.ng-leave.ng-leave-active,
    .period-switch.ng-enter {
        top: -100%;
    }

    .period-switch.ng-leave,
    .period-switch.ng-enter.ng-enter-active {
        top: 0;
    }

    //Massia nav
    @media screen and (min-width: 768px) {
        massia-navigation {
            .separator-item-sub {
                &:after {
                    position: absolute;
                    right: 10px;
                    left: 10px;
                    bottom: 0;
                    content: ' ';
                    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
                }
            }

            .hover-back {
                transition: background-color 0.2s;

                &:hover {
                    background: #f5f5f5;
                }
            }

            .dropdown-menu>li>a {
                &:hover {
                    background: none !important;
                }

                &:focus {
                    background: none !important;
                }
            }

            .open>.dropdown-menu {
                opacity: 1;
                transform: scale(1, 1);
                transform-origin: top left;
                transition: all 0.2s;
            }

            .dropdown-menu {
                opacity: 0;
                transform: scale(0, 0);
                transform-origin: top left;
                transition: all 0.2s;
                display: block;
                z-index: 60000 !important;
            }

            .sub-menu-header {
                position: relative;
                display: flex;
                justify-content: space-between;

                .glyphicon-chevron-right {
                    opacity: 0.4;
                    transition: opacity 0.3s;
                    transition: margin-right 0.2s;
                }

                .rotate {
                    opacity: 1;
                    margin-right: -8px;
                }

                .openSub {
                    opacity: 1;
                    transform: scale(1, 1);
                    transform-origin: top left;
                    transition: all 0.2s;
                }

                .closeSub {
                    opacity: 0;
                    transform: scale(0, 0);
                    transform-origin: top left;
                    transition: all 0.2s;
                }

                .sub-menu-nav {
                    z-index: 10;
                    position: absolute;
                    left: 100%;
                    background: white;
                    outline: 1px solid rgba(0, 0, 0, 0.15);
                    padding: 0px;
                    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                    list-style: none;
                    top: 0;
                    max-height: 1200%;
                    overflow: auto;

                    .sub-menu-nav-item {
                        padding: 10px 20px;
                        width: 250px;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        a {
                            display: block;
                            clear: both;
                            font-weight: 400;
                            line-height: 1.42857143;
                            white-space: nowrap;
                            padding-left: 5px 20px;
                        }
                    }
                }
            }
        }
    }

    .grab {
        cursor: grab;
    }

    table {
        &.buttons-custom {
            button {
                outline: 0;
            }

            .glyphicon-ok {
                color: green;

                &.ng-enter {
                    transition: all 0.25s;
                    transform: rotate(-90deg);
                }

                &.ng-enter-active {
                    transform: rotate(0deg);
                }
            }

            .glyphicon-remove {
                color: red;

                &.ng-enter {
                    transition: all 0.25s;
                    transform: rotate(-90deg);
                }

                &.ng-enter-active {
                    transform: rotate(0deg);
                }
            }
        }
    }

    .theme-color {
        width: 30px;
        height: 30px;
        border-radius: 2em;
        margin: auto;
    }
}