#id-apps {
  .ac-treeview-tree {
    overflow-x: auto;
    margin: 0 0 -10px;
    padding-left: 10px;
    padding-right: 10px;

    .node-selected {
      background: #CBE8F6;
      border: 1px solid #26A0DA;
    }

    .ac-treeview-root-list {
      width: 100%;
      padding-left: 0;
    }

    .treeview-no-actions {
      display: none;
      vertical-align: text-top;
      padding-left: 15px;
      padding-right: 15px;
    }

    .divider + .divider {
      display: none;

      + .treeview-no-actions {
        display: block;
      }
    }

    .treeview-node-menu.dropdown .dropdown-menu {
      min-width: 290px;
    }

    .item-inactive {
      font-style: italic;
      color: #B2B1B5;
    }

    .item-inactive .glyphicon {
      color: #B2B1B5 !important;
    }

    ol {
      padding-left: 20px;
    }

    .node-leaf-label {
      line-height: 1.5em;
    }

    .node-name {
      vertical-align: middle;
    }

    .node-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .node-icon,
    .node-folder-icon {
      margin-right: 3px;
    }

    .treeview-node-menu {
      min-width: 200px;

      .text-muted p {
        height: 20px
      }
    }
    .dropdown-menu > li > a {
      .glyphicon:not(.glyphicon-blank) + span {
        margin-left: 8px;
      }
    }

    .ac-loading-wrapper-left {
      padding-left: 8px;
    }
  }
}