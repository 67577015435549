.btn {
  outline-color: transparent !important;

  > span + span {
    margin-left: calc($padding-base-horizontal / 2);
  }

  .glyphicon {
    top: 2px;
  }
}