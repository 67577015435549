.massia-context-menu {
    border-radius: 5px;
    overflow: hidden;

    a {
        transition: all 0.3s;
        &:hover {
            background-color: $primary !important;
        }
    }
}
