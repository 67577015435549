/****************
** TABLE
****************/
.st-filter-btn {
  .btn-filter {
    font-size: 10px;
    opacity: 0.5;
    color: #333;
  }

  .btn-filter:hover,
  .filter-active {
    opacity: 1;
    color: #CD1041 !important;
  }

  .bg-filter {
    color: #333;

    .multiSelect > button,
    input {
      font-weight: normal;
    }
  }
}

.btn-erase {
  color: #fff !important;
  background-color: #999;
  border-color: #777;

  &:hover {
    background-color: #555;
    border-color: #333;
  }
}

.st-sort {
  cursor: pointer;
}

/* .st-sort:after {
  padding-top: 1px;
  //float: right;
  opacity: 0.5;
  //font-weight: bold;
  content: '>';
} */

.st-sort:hover:after {
  opacity: 1;
}

/* .st-sort-ascent:after {
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);

  color: #CD1041;
  opacity: 0.8;
}

.st-sort-descent:after {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);

  color: #CD1041;
  opacity: 0.8;
} */