.mas-nav-list {
    list-style: none;
    padding: 0;

    hr {
        margin: 0;
    }

    .mas-nav-item {
        height: 60px;
        &-link {
            margin: 0px;
            cursor: pointer;
            &:hover {
                @include gradient(background, $primary);
                font-weight: bold;
                color: white;
            }

            &.mas-nav-active {
                @include gradient(background, $primary);
                font-weight: bold;
                color: white;
            }
        }
    }
}

/* .mas-nav-list {
    list-style: none;
    padding: 0;

    .mas-nav-item {
        &-link {
            margin: 5px 0px 5px 0px;
            cursor: pointer;
            &:hover {
                @include gradient(background, $primary);
                border-radius: 2px;
                font-weight: bold;
                color: white;
            }

            &.mas-nav-active {
                border-radius: 2px;
                @include gradient(background, $primary);
                font-weight: bold;
                color: white;
            }
        }
    }
} */
