massia-fieldset {
    .mas-field {
        fieldset {
            margin: unset;
            padding: unset;
            margin-inline-start: 2px;
            margin-inline-end: 2px;
            padding-block-start: 0.35em;
            padding-inline-start: 0.75em;
            padding-inline-end: 0.75em;
            padding-block-end: 0.625em;
            border: 1px solid rgba(0, 0, 0, 0.44);
            border-radius: 5px;

            legend {
                display: block;
                width: unset;
                padding: 5px;
                margin-bottom: 0px;
                font-size: unset;
                line-height: inherit;
                color: #4c4c4c;
                border: 0;
            }
        }
    }
}
