.form-control {
    &:focus {
        box-shadow: none;
        -webkit-box-shadow: none;
    }
}

.control-label {
    font-weight: 300;
    span + .glyphicon-question-sign,
    span + .glyphicon-asterisk {
        font-size: $font-size-h6;
        top: calc(calc($font-size-h6 * -1) / 2);
        //top: -$font-size-h6 / 2;
    }
}

.form-horizontal {
    ul.form-control-static {
        padding-left: 15px;
    }
}

.info-message {
    padding-left: 0;
    span {
        top: 0;
        font-size: $line-height-computed;
        @extend .form-control-static;
    }
}

.validation-message + .info-message {
    display: none;
}

.radio-inline,
.checkbox-inline {
    padding-bottom: 7px;
}

.input-group-btn-spaced {
    padding-left: calc($grid-gutter-width / 2);
}

offre-form .form-group {
    margin-bottom: 6px;
}

[type='checkbox'] {
    float: right;
    height: 20px;
    width: 20px;
}

hr {
    margin-top: 5px;
    margin-bottom: 10px;
}

.form-container {
    padding: 10px;
    border: solid 1px #cccccc;
    margin: 10px;
    background-color: rgb(244, 244, 244);
}

.form-container hr {
    border-top: 2px solid #ffffff;
}

.textarea-height-3 {
    height: 114px !important;
}

table {
    &.facture select {
        height: 34px !important;
    }
    select {
        // height: 26px !important;
        padding: 0 !important;
    }
}
.app-performance table select {
    height: 34px !important;
    padding: 0 !important;
}

.mandatory-field {
    color: $brand-danger;
    font-weight: bold;
}

.non-conforme {
    color: red !important;
    &-bg {
        background: red;
    }
}

.alerte {
    color: orange !important;
    &-bg {
        background: orange;
    }
}

.conforme {
    color: green !important;
    &-bg {
        background: green;
    }
}

.tolerance-conforme {
    color: blue !important;
    &-bg {
        background: blue;
    }
}

.interpole {
    font-style: italic !important;
}

.non-conforme-back {
    background-color: darkred !important;
    color: white;
    font-size: 24px;
}

.alerte-back {
    background-color: orangered !important;
    color: white;
    font-size: 24px;
}

.conforme-back {
    background-color: green !important;
    color: white;
    font-size: 24px;
}

.tolerance-conforme-back {
    background-color: blue !important;
    color: white;
    font-size: 24px;
}

.non-controle-back {
    background-color: gray !important;
    color: white;
    font-size: 24px;
}

.form-horizontal .control-label {
    padding-top: 0px !important;
    line-height: 34px;
}

.form-control-static,
#id-apps .info-message span {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0;
    min-height: 20px !important;
    line-height: 30px;
}

.form-group a {
    color: #7db8a4;
}

.grille-path-element {
    font-weight: bold;
    padding: 0 5px;
}

.border-primary {
    border: solid 2px $brand-primary;
}

@import 'form/datepicker';
@import 'form/multiselect';
@import 'form/uiselect';
