/* massia-electron-configuration {
  .configuration {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba($color: black, $alpha: 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 15000;

    .config-modal {
      position: relative;
      z-index: 1000;
      background: whitesmoke;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      width: 100%;
      border-top: 3px solid whitesmoke;
      border-bottom: 3px solid whitesmoke;
      background-color: $brand-primary;
      animation-name: scalemodal;
      animation-duration: 0.5s;
    }

    .container {
      padding: 15px 50px 30px 50px;
      color: white;

      .title {
        padding: 10px;
      }

      .body {
        display: flex;
        flex-direction: column;
        padding: 10px;

        .field-label {
          font-size: 1.3em;
        }

        input {
          margin-top: 5px;
        }
      }

      .footer-modal {
        padding: 10px;
        .btn {
          float: right;
        }
      }
    }
  }

  .slide {
    animation-name: slide-in;
    animation-duration: 0.5s;
  }

  .slide.ng-hide {
    animation-name: slide-in;
    animation-duration: 0.5s;
  }

  @keyframes slide-in {
    from {
      opacity: 0;
      transform: translateX(-25%);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slide-out {
    from {
      opacity: 1;
      transform: translateX(0);
    }

    to {
      opacity: 0;
      transform: translateX(25%);
    }
  }

  @keyframes scalemodal {
    from {
      transform: scale(0, 0);
    }

    to {
      transform: scale(1, 1);
    }
  }
}
 */

main-electron {
    @keyframes fadeInConfig {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.6;
        }
    }

    @keyframes zoomIn {
        0% {
            transform: scale(0, 0) translate(-50%, -50%);
        }
        100% {
            transform: scale(1, 1) translate(-50%, -50%);
        }
    }

    .backdrop-config {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 5000;
        background: #000;
        animation-name: fadeInConfig;
        animation-fill-mode: forwards;
        animation-duration: 0.2s;
    }

    .dialog {
        width: 50%;
        height: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 6000;
        border-radius: 5px;
        background: white;
        overflow: hidden;
        animation-name: zoomIn;
        animation-fill-mode: forwards;
        animation-duration: 0.2s;
    }

    @media screen and (max-width: 768px) {
        .dialog {
            width: 90%;
        }
    }

    .nav {
        list-style: none;
        .nav-item {
        }
    }
}
