/****************
** Drag&Drop
****************/
#id-apps {
  .drag-drop {
    background: #fff;
    border: 1px dashed transparent;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 10px 0 5px;
  }

  .drag-drop:hover {
    background: #E5F3FB;
    border: 1px solid #70C0E7;
    cursor: pointer;
  }

  .drag-over > div {
    background: #f0f9ff;
    border: 1px dashed #bed2db;
  }

  .dragged {
    opacity: 0.5;
  }

  .dragged {
    background: #fff !important;
    border-color: transparent !important;
  }

  .dragged .drag-drop {
    background: #CBE8F6 !important;
    border-color: transparent !important;
  }
}