//
// Load core variables and mixins
// --------------------------------------------------
@import 'bootstrap/mixins';

//
// Alerts
// --------------------------------------------------
.alert-success {
    @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}

.alert-info {
    @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}

.alert-warning {
    @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}

.alert-danger {
    @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
}

//
// Typography
// --------------------------------------------------
@include text-emphasis-variant('.text-primary', $brand-primary);

@include text-emphasis-variant('.text-success', $state-success-text);

@include text-emphasis-variant('.text-info', $state-info-text);

@include text-emphasis-variant('.text-warning', $state-warning-text);

@include text-emphasis-variant('.text-danger', $state-danger-text);

.bg-primary {
    color: #fff;
}

@include bg-variant('.bg-primary', $brand-primary);

@include bg-variant('.bg-success', $state-success-bg);

@include bg-variant('.bg-info', $state-info-bg);

@include bg-variant('.bg-warning', $state-warning-bg);

@include bg-variant('.bg-danger', $state-danger-bg);

//
// Buttons
// --------------------------------------------------
.btn-default {
    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}

.btn-primary {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

.btn-primary-light {
    @include button-variant($btn-primary-color, lighten($btn-primary-bg, 10%), $btn-primary-border);
}

.btn-primary-dark {
    @include button-variant($btn-primary-color, darken($btn-primary-bg, 10%), $btn-primary-border);
}

// Success appears as green
.btn-success {
    @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

// Info appears as blue-green
.btn-info {
    @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

// Warning appears as orange
.btn-warning {
    @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

// Danger and error appear as red
.btn-danger {
    @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

//
// Dropdowns
// --------------------------------------------------
// The dropdown menu (ul)
.dropdown-menu {
    background-color: $dropdown-bg;
    border: 1px solid $dropdown-fallback-border; // IE8 fallback
    border: 1px solid $dropdown-border;

    // Dividers (basically an hr) within the dropdown
    .divider {
        @include nav-divider($dropdown-divider-bg);
    }

    // Links within the dropdown menu
    >li>a {
        color: $dropdown-link-color;
    }
}

// Hover/Focus state
.dropdown-menu>li>a {

    &:hover,
    &:focus {
        color: $dropdown-link-hover-color;
        background-color: $dropdown-link-hover-bg;
    }
}

// Active state
.dropdown-menu>.active>a {

    &,
    &:hover,
    &:focus {
        color: $dropdown-link-active-color;
        background-color: $dropdown-link-active-bg;
    }
}

// Disabled state
.dropdown-menu>.disabled>a {

    &,
    &:hover,
    &:focus {
        color: $dropdown-link-disabled-color;
    }
}

// Dropdown section headers
.dropdown-header {
    color: $dropdown-header-color;
}

//
// List groups
// --------------------------------------------------
.list-group-item {
    background-color: $list-group-bg;
    border: 1px solid $list-group-border;
}

// Interactive list items
a.list-group-item,
button.list-group-item {
    color: $list-group-link-color;

    .list-group-item-heading {
        color: $list-group-link-heading-color;
    }

    // Hover state
    &:hover,
    &:focus {
        color: $list-group-link-hover-color;
        background-color: $list-group-hover-bg;
    }
}

.list-group-item {

    // Disabled state
    &.disabled,
    &.disabled:hover,
    &.disabled:focus {
        background-color: $list-group-disabled-bg;
        color: $list-group-disabled-color;

        // Force color to inherit for custom content
        .list-group-item-heading {
            color: inherit;
        }

        .list-group-item-text {
            color: $list-group-disabled-text-color;
        }
    }

    // Active class on item itself, not parent
    &.active,
    &.active:hover,
    &.active:focus {
        color: $list-group-active-color;
        background-color: $list-group-active-bg;
        border-color: $list-group-active-border;

        // Force color to inherit for custom content
        .list-group-item-heading,
        .list-group-item-heading>small,
        .list-group-item-heading>.small {
            color: inherit;
        }

        .list-group-item-text {
            color: $list-group-active-text-color;
        }
    }
}

// Contextual variants
@include list-group-item-variant(success, $state-success-bg, $state-success-text);
@include list-group-item-variant(info, $state-info-bg, $state-info-text);
@include list-group-item-variant(warning, $state-warning-bg, $state-warning-text);
@include list-group-item-variant(danger, $state-danger-bg, $state-danger-text);

//
// Navbar
// --------------------------------------------------
.navbar-default {
    background-color: $navbar-default-bg;
    border-color: $navbar-default-border;

    .navbar-brand {
        color: $navbar-default-brand-color;

        &:hover,
        &:focus {
            color: $navbar-default-brand-hover-color;
            background-color: $navbar-default-brand-hover-bg;
        }
    }

    .navbar-text {
        color: $navbar-default-color;
    }

    .navbar-nav {
        >li>a {
            color: $navbar-default-link-color;

            &:hover,
            &:focus {
                color: $navbar-default-link-hover-color;
                background-color: $navbar-default-link-hover-bg;
            }
        }

        >.active>a {

            &,
            &:hover,
            &:focus {
                color: $navbar-default-link-active-color;
                background-color: $navbar-default-link-active-bg;
            }
        }

        >.disabled>a {

            &,
            &:hover,
            &:focus {
                color: $navbar-default-link-disabled-color;
                background-color: $navbar-default-link-disabled-bg;
            }
        }
    }

    .navbar-toggle {
        border-color: $navbar-default-toggle-border-color;

        &:hover,
        &:focus {
            background-color: $navbar-default-toggle-hover-bg;
        }

        .icon-bar {
            background-color: $navbar-default-toggle-icon-bar-bg;
        }
    }

    .navbar-collapse,
    .navbar-form {
        border-color: $navbar-default-border;
    }

    // Dropdown menu items
    .navbar-nav {

        // Remove background color from open dropdown
        >.open>a {

            &,
            &:hover,
            &:focus {
                background-color: $navbar-default-link-active-bg;
                color: $navbar-default-link-active-color;
            }
        }

        @media (max-width: $grid-float-breakpoint-max) {

            // Dropdowns get custom display when collapsed
            .open .dropdown-menu {
                >li>a {
                    color: $navbar-default-link-color;

                    &:hover,
                    &:focus {
                        color: $navbar-default-link-hover-color;
                        background-color: $navbar-default-link-hover-bg;
                    }
                }

                >.active>a {

                    &,
                    &:hover,
                    &:focus {
                        color: $navbar-default-link-active-color;
                        background-color: $navbar-default-link-active-bg;
                    }
                }

                >.disabled>a {

                    &,
                    &:hover,
                    &:focus {
                        color: $navbar-default-link-disabled-color;
                        background-color: $navbar-default-link-disabled-bg;
                    }
                }
            }
        }
    }

    // Links in navbars
    .navbar-link {
        color: $navbar-default-link-color;

        &:hover {
            color: $navbar-default-link-hover-color;
        }
    }

    .btn-link {
        color: $navbar-default-link-color;

        &:hover,
        &:focus {
            color: $navbar-default-link-hover-color;
        }

        &[disabled],
        fieldset[disabled] & {

            &:hover,
            &:focus {
                color: $navbar-default-link-disabled-color;
            }
        }
    }
}

//
// Navs
// --------------------------------------------------
.nav-pills {
    >li {

        // Active state
        &.active>a {

            &,
            &:hover,
            &:focus {
                color: $nav-pills-active-link-hover-color;
                background-color: $nav-pills-active-link-hover-bg;
            }
        }
    }
}

//
// Pagination
// --------------------------------------------------
.pagination {
    >li {

        >a,
        >span {
            color: $pagination-color;
            background-color: $pagination-bg;
            border: 1px solid $pagination-border;
        }
    }

    >li>a,
    >li>span {

        &:hover,
        &:focus {
            color: $pagination-hover-color;
            background-color: $pagination-hover-bg;
            border-color: $pagination-hover-border;
        }
    }

    >.active>a,
    >.active>span {

        &,
        &:hover,
        &:focus {
            color: $pagination-active-color;
            background-color: $pagination-active-bg;
            border-color: $pagination-active-border;
        }
    }

    >.disabled {

        >span,
        >span:hover,
        >span:focus,
        >a,
        >a:hover,
        >a:focus {
            color: $pagination-disabled-color;
            background-color: $pagination-disabled-bg;
            border-color: $pagination-disabled-border;
        }
    }

    >li {

        >span[disabled],
        >span[disabled]:hover,
        >span[disabled]:focus,
        >a[disabled],
        >a[disabled]:hover,
        >a[disabled]:focus {
            color: $pagination-disabled-color;
            background-color: $pagination-disabled-bg;
            border-color: $pagination-disabled-border;
        }
    }
}

//
// Panels
// --------------------------------------------------
.panel {
    background-color: $panel-bg;

    >.panel-body+.table,
    >.panel-body+.table-responsive,
    >.table+.panel-body,
    >.table-responsive+.panel-body {
        border-top: 1px solid $table-border-color;
    }
}

.panel-group {
    .panel-heading {
        +.panel-collapse>.panel-body {
            border-top: 0;
        }

        +.panel-collapse>.list-group {
            border-top: 1px solid $panel-inner-border;
        }
    }
}

// Optional footer (stays gray in every modifier class)
.panel-footer {
    background-color: $panel-footer-bg;
    border-color: $panel-inner-border;
}

// Contextual variations
.panel-default {
    @include panel-variant($panel-default-border, $panel-default-text, $panel-default-heading-bg, $panel-default-border);
}

.panel-primary {
    @include panel-variant($panel-primary-border, $panel-primary-text, $panel-primary-heading-bg, $panel-primary-border);
}

.panel-success {
    @include panel-variant($panel-success-border, $panel-success-text, $panel-success-heading-bg, $panel-success-border);
}

.panel-info {
    @include panel-variant($panel-info-border, $panel-info-text, $panel-info-heading-bg, $panel-info-border);
}

.panel-warning {
    @include panel-variant($panel-warning-border, $panel-warning-text, $panel-warning-heading-bg, $panel-warning-border);
}

.panel-danger {
    @include panel-variant($panel-danger-border, $panel-danger-text, $panel-danger-heading-bg, $panel-danger-border);
}

//
// Progress bars
// --------------------------------------------------
.progress {
    background-color: $progress-bg;
}

// Bar of progress
.progress-bar {
    color: $progress-bar-color;
    background-color: $progress-bar-bg;
}

// Variations
.progress-bar-success {
    @include progress-bar-variant($progress-bar-success-bg);
}

.progress-bar-info {
    @include progress-bar-variant($progress-bar-info-bg);
}

.progress-bar-warning {
    @include progress-bar-variant($progress-bar-warning-bg);
}

.progress-bar-danger {
    @include progress-bar-variant($progress-bar-danger-bg);
}

//
// Wells
// --------------------------------------------------
.well {
    background-color: $well-bg;
    border: 1px solid $well-border;
}

.widget-container {
    .panel {
        margin-bottom: 10px;
    }

    .panel-body {
        padding: 5px;
    }

    .divWidget {

        div[class^='col'],
        div[class*=' col'] {
            padding: 0 6px;
        }

        .widget {
            background: #f5f5f5;
            border: solid 1px $massia_performance;
            margin: 5px;
            overflow: hidden; //auto;
            padding: 0 15px 0 0;
            height: 310px;

            @media (max-width: 767px) {
                min-height: 270px;
            }

            @media (min-width: 768px) {
                min-height: 270px;
            }

            @media (min-width: 992px) {
                min-height: 195px;

                h4 {
                    margin: 5px 0;
                }
            }

            @media (min-width: 1200px) {
                min-height: 225px;
            }

            div[class^='col'],
            div[class*=' col'] {
                margin: 0;
                padding: 0;
            }

            canvas {
                max-height: 260px;
                margin: 0;
                padding: 0;
            }
        }
    }
}

input[type='number']:not(.btn-level)::-webkit-outer-spin-button,
input[type='number']:not(.btn-level)::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0px;
}

input[type='number']:not(.btn-level) {
    -moz-appearance: textfield;
}

.table-prix-ht {
    font-weight: bold;
}

.lg-popover.popover {
    max-width: 45%;
}

.xl-popover.popover {
    max-width: 95%;

    .popover-content {
        padding: 10px 5px;
    }
}

.xxl-popover.popover {
    max-width: 95%;
    min-width: 25%;

    .popover-content {
        padding: 10px 5px;
    }
}

.xxl-popover.popover.decale-right {
    max-width: 95%;
    min-width: 25%;
    left: 245px !important;

    .popover-content {
        padding: 10px 5px;
    }

    .arrow {
        margin-left: 0px;
        left: 0px;
    }
}

.popover-table {
    z-index: 50000;
}

.btn-link {
    &.btn-primary {
        color: $brand-primary;
    }
}

.dark {
    .massia-icon-star::before {
        color: #535353;
    }
}

.form-table {
    background-color: #e5e5e5;
}

.form-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 55px;
    background-color: #f4f4f4;
    z-index: 999;
    padding: 11px;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    margin: 10px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid $brand-primary;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $brand-primary transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}