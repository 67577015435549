//
// Datepicker
// --------------------------------------------------
[ac-datepicker] {
    .uib-datepicker-popup {
        .btn-danger {
            @extend .btn-primary;
        }

        .btn-info {
            @extend .btn-primary;
        }

        .btn-success {
            @extend .btn-primary;
        }
    }

    [uib-daypicker] {
        td:first-child {
            color: $gray-dark;
        }

        .uib-day .btn {
            &.btn-default.active {
                @extend .btn-primary;

                .text-info {
                    color: #fff;
                }
            }

            &:hover:not([disabled]) {
                @extend .bg-primary;

                .text-muted {
                    color: #fff !important;
                }
            }
        }
    }

    .btn-calendar {
        @extend .btn-primary;
    }

    .uib-datepicker-popup {
        .btn-danger {
            @extend .btn-primary;
        }

        .btn-info {
            @extend .btn-primary;
        }

        .btn-success {
            @extend .btn-primary;
        }

        .uib-day .btn {
            &:hover:not([disabled]) {
                background-color: $primary-light;
            }
            &.btn-info {
                @extend .btn-primary;
            }
        }
    }
}

[uib-timepicker] {
    &[uib-timepicker-small] {
        input {
            width: 30px;
            padding: 0;
            font-size: 12px;
        }
    }
}

//
// Multiselect
// --------------------------------------------------
.multiSelect {
    .multiSelectItem:not(.multiSelectGroup).selected {
        background-color: $gray-lighter;
    }

    .multiSelectItem,
    .multiSelectGroup {
        .tickMark {
            @extend .bg-primary;
        }

        &:not(.selected):hover {
            @extend .bg-primary;

            label {
                color: #fff;
            }
        }
    }

    .clearButton {
        @extend .btn-default;
    }

    .helperButton {
        @extend .btn-primary;
    }
}

//
// UIselect
// --------------------------------------------------
.ui-select-bootstrap {
    .btn-default-focus {
        background-color: #fff;
    }

    .ui-select-choices-row {
        a {
            &,
            &:focus,
            &:hover {
                color: $text-color !important;
            }
        }

        &:hover,
        &.active {
            @extend .bg-primary;
            &.active a,
            a {
                &,
                &:focus,
                &:hover {
                    color: #fff !important;
                }
            }
        }
    }
}

//
// Pagination
// --------------------------------------------------
.pagination-wrapper .pagination {
    > li {
        &.active .pagination-current-page {
            background-color: $btn-default-bg;
            @extend .text-primary;
        }

        .btn-default:not(.pagination-current-page) {
            @extend .text-primary;
            border-color: inherit;

            &:hover:not([disabled]) {
                @extend .bg-primary;
            }

            &[disabled] {
                @extend .btn-default;
            }
        }
    }
}

//
// Modal
// --------------------------------------------------
[uib-modal-window] {
    .modal-content {
        .modal-header.modal-info {
            @extend .bg-primary;

            h4 {
                color: #fff;
            }
        }

        .modal-footer {
            .btn-info {
                @extend .bg-primary;
            }
        }
    }
}

//
// Accordion
// --------------------------------------------------
.panel-group .accordion,
.accordion {
    &-invalid {
        .panel-heading {
            color: $state-danger-text;
            background-color: $state-danger-bg;
            border-color: $state-danger-border;
        }
    }
    &-offre-a-valider {
        .panel-heading {
            color: black;
            border-color: $state-danger-border;                  
            opacity: 1;
            background-color: #ff5917 !important;
        }
    }
}

//
// Form
// --------------------------------------------------
.form-control {
    &:focus {
        border-color: $gray;
    }
}

.input-group-addon {
    color: #fff;
    background-color: $primary-dark;
}

.form-group {
    label,
    .label {
        color: $primary-dark;
        line-height: 34px;
        font-weight: normal;
        font-size: 100%;
        margin: 0;
        &.date {
            line-height: 22px;
            padding: 0;
        }
    }
}

select.ng-empty,
.placeholder {
    color: $gray-light !important;

    option {
        color: $gray;
    }
}

//
// Panel
// --------------------------------------------------
.panel-default {
    .panel-separator {
        border-color: $panel-default-border;
    }

    .panel-body {
        border-color: $panel-default-border;
    }

    .panel-footer {
        border-color: $panel-default-border;
    }
}

.panel-group {
    .panel-heading {
        background-color: $brand-primary;
        opacity: 0.66;
        color: #000000;
    }

    .panel-default {
        background-color: #f4f4f4;
    }

    border-color: $panel-default-border;
}

//
// Table
// --------------------------------------------------
.dropdown-menu {
    &.dropdown-primary {
        > li .glyphicon {
            @extend .text-primary;
        }
    }
}

//
// Table
// --------------------------------------------------
.table {
    tr.selected {
        background-color: $primary-light !important;
    }

    tbody tr:hover:not(.no-hover) {
        td {
            background-color: $primary-lighter !important;
        }
        background-color: $primary-lighter !important;
    }

    > thead > tr > th {
        @extend .bg-primary;
        vertical-align: middle;
        border-color: $primary-dark;
        + th {
            border-color: $primary-dark;
        }
    }

    tfoot {
        border-color: $primary-lighter;
        tr > td {
            padding-left: 5px;
        }
    }

    /*.btn.btn-link {
    color: $gray-dark;

    &:hover,
    &:focus {
      color: $gray-darker;
    }
  }*/

    .st-sort-ascent:after,
    .st-sort-descent:after,
    .st-filter-btn .btn-filter,
    .btn-reset-filters {
        color: #fff;
    }

    .st-filter-btn .btn-filter:hover,
    .st-filter-btn .filter-active,
    .btn-reset {
        color: $gray-dark !important;
    }
}

.navbar-collapse-bar {
    hr {
        @extend .bg-primary;
    }

    .btn {
        @extend .bg-primary;
    }
}

//---- SWITCH
.switch {
    position: relative;
    width: 44px;
    height: 22px;
    float: left;
}
.switch.center {
    float: initial;
    margin-top: 6px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    float: right;
    cursor: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: $brand-primary;
}

input:focus + .slider {
    box-shadow: 0 0 1px $brand-primary;
}

input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
//----

#formModePaiement .form-group div[class^='col'],
div[class*=' col'] {
    padding-left: 8px;
    padding-right: 11px;
}
#formModePaiement {
    .calculEcheance {
        input,
        select {
            text-align: center;
            padding-left: 0.4%;
            padding-right: 0.4%;
        }
    }
}
