[ac-pageslide] {
  .btn-slider-close {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    margin: 3px 0 3px 3px;

    @extend .btn;
    @extend .btn-link;
  }

  .slider-content {
    padding: 40px 0 0 0;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
  .slider-contenu{
    margin:5px 5px;
  }
  &.ac-pageslide-right {
    border-left: 5px solid $gray-light;
    top: 0 !important;
    bottom: $footer-size !important;
    background: $body-bg;
  }

  .slider-heading {
    padding: 10px 35px 10px 10px;
    background-color: $primary;
    color: #fff;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    min-height: 40px;

    h4 {
      margin: 0;
    }

    .form-inline {
      .btn-link {
        border: 0;
        padding-top: 0;
        padding-bottom: 0;
        text-decoration: none;
      }

      .glyphicon + span {
        padding-left: 5px;
      }

      a {
        color: #fff;

        &:focus,
        &:hover {
          color: #fff;
        }
      }
    }
  }

  .panel-group {
    margin-bottom: 0;
  }
}

.slider-fixed {
  position: relative;
  // height: 100%;

  .slider-content {
    padding-top: 0;
  }

  > .panel,
  [ac-pageslide] {
    //min-height: 528px;
  }

  [ac-pageslide] {
    padding-top: 40px;
  }

  [ac-pageslide].ac-pageslide-right {
    border-left: 1px solid;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: $gray-light;

    position: fixed !important;
    top: 166px !important;
    right: 15px !important;
  }
}