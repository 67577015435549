// ARCADE
$arcade_bleugris: rgb(89, 141, 172) !default;
$arcade_vertbleu: rgb(80, 175, 156) !default;
$arcade_grisbleu: rgb(232, 248, 255) !default;
$arcade_jauneorange: rgb(244, 201, 0) !default;
$arcade_orange: rgb(242, 148, 0) !default;
$arcade_rouge: rgb(156, 3, 60) !default;
$arcade_rougeclair: rgb(255, 7, 114) !default;
$arcade_vertbleuclair: rgb(118, 216, 190) !default;
$arcade_rougefonce: rgb(86, 2, 38) !default;
$arcade_jaune: rgb(214, 169, 6) !default;
$arcade_grisfonce: rgb(42, 45, 40) !default;
$arcade_bleugris_texte: rgb(84, 114, 132) !default;
// MASSIA
$massia: rgb(76, 76, 76) !default;
$massia_gestion: rgb(247, 148, 29) !default;
$massia_laboratoire: rgb(0, 170, 185) !default;
$massia_qse: rgb(134, 185, 25) !default;
$massia_maintenance: rgb(177, 41, 47) !default;
$massia_performance: rgb(161, 25, 91) !default;

$labo-theme: (
  "primary": #00acc1,
  "secondary": #42a5f5
);

// FONTS
@font-face {
  font-family: 'Franklin Gothic Book';
  src: url('../fonts/FRABK.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$FranklinFont: 'Franklin Gothic Book', Arial, sans-serif !default;
$CenturyFont: 'Century Gothic', CenturyGothic, Geneva, AppleGothic, sans-serif !default;

// FOOTER
$footer-height: 50px;