.fc-button {
    background-color: $brand-primary;
    border-color: $brand-primary;

    color: white;
}

#massia_calendar {
    table {
        border-spacing: initial;
        background-color: initial;
    }

    .fc-day {
        transition: background-color 0.1s ease-in-out;
    }

    .calendar-select {
        background-color: rgba($color: $brand-success, $alpha: 0.4);
    }
}
