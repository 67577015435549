massia-page-loading {
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    .small {
        width: 64px;
        height: 64px;

        div {
            width: 60px;
            height: 60px;
            margin: 5px;
            border: 6px solid $brand-primary;
        }
    }

    .large {
        width: 120px;
        height: 120px;

        div {
            width: 104px;
            height: 104px;
            margin: 8px;
            border: 12px solid $brand-primary;
        }
    }

    .bg-blur {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        filter: blur(10px);
    }

    .lds-ring {
        display: inline-block;
        position: relative;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $brand-primary transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .row {
        p {
            font-size: 24px;
            font-weight: bold;
            color: $brand-primary;
        }
    }
}
