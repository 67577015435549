/****************
** DATEPICKER
****************/
#id-apps {
  .ac-datepicker {
    position: relative;
    .form-group-sm .picker-btn button {
      height: 28px;
    }

    .dropdown-menu {
      min-width: 272px;
    }

    &.ac-datepicker-sm {
      width: 150px;
      .picker-input {
        text-align: center;
      }
    }
  }

  [ac-datepicker] .input-group {
    margin-bottom: 0;
  }
  
  .datepicker-popup-wrap {
    .btn-sm {
      border: none !important;
    }

    /*.btn-info:not(.active),
    .btn-success,
    .btn-danger {
      border-radius: 0 !important;
      margin: 0 2px !important;
      background-color: #515151 !important;
      color: white !important;
    }*/

    &.dropdown-menu {
      z-index: 9999 !important;
    }
  }

  .uib-datepicker .uib-title {
    width: 100%;
  }

  .uib-day button, .uib-month button, .uib-year button {
    min-width: 100%;
  }

  .uib-left, .uib-right {
    width: 100%
  }

  .uib-datepicker-popup.dropdown-menu {
    display: block !important;
    float: none !important;
    margin: 0 !important;
  }

  .uib-button-bar {
    padding: 10px 9px 2px;
  }

  .full button span {
    background-color: limegreen;
    border-radius: 32px;
    color: black;
  }
  .partially button span {
    background-color: orange;
    border-radius: 32px;
    color: black;
  }

  .uib-position-measure {
    display: block !important;
    visibility: hidden !important;
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }

  .uib-position-scrollbar-measure {
    position: absolute !important;
    top: -9999px !important;
    width: 50px !important;
    height: 50px !important;
    overflow: scroll !important;
  }

  .uib-position-body-scrollbar-measure {
    overflow: scroll !important;
  }

  .uib-datepicker-popup {
    /*.btn-danger,
    .btn-info:not(.active),
    .btn-success {
      border-radius: 0 !important;
      border: none;
      margin: 0 2px !important;
      background-color: #515151 !important;
      color: #fff !important;
    }*/

    .btn-default,
    .btn-default:hover {
      border: none;
    }

    td:first-child {
      //color: #31B0D5 !important;
      border-right: 1px solid lightgray;
    }
  }
}