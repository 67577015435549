#tree-root {
    .animate {
        transition: all 0.3s ease;
    }

    .rotate {
        transform: rotate(-180deg);
    }

    .ui-tree-line {
        position: relative;
        &:hover {
            background-color: #e0e0e0;
        }
    }

    .ui-tree-empty {
        transition: all 0.3s;
        overflow: hidden;
        position: relative;

        &.ng-hide {
            transform: translateX(-50%);
            opacity: 0;
        }
    }
}
