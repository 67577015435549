.login {
	width: 50%;
	background-image: url('../img/img_background.jpg');
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
	font-family: $CenturyFont;

	.n-content {
		background-color: rgba(0, 0, 0, 0.7); /* Noir avec opacité de 70% */
		padding: 30px;
		height: 100vh;
	}

	.mas-p-relative {
		position: relative;
	}

	.img-container {
		img {
			width: 100%;
		}
	}

	h1 {
		color: white;
		font-weight: 700;
		margin: 0px;
	}

	p {
		color: white;
		margin: 0px;
	}

	&-welcome-container {
		gap: 15px;
	}

	&-arcade-container {
		width: 100%;
	}

	&-logo-massia {
		width: 100%;
	}

	&-logo-arcade {
		width: 100%;
	}

	&-arcade-link {
		width: 20%;
	}

	&-form {
		label {
			color: #f28c1d;
		}

		.form-control {
			background-color: transparent !important;
			border: 1px solid #fff !important;
			color: #fff !important;
			border-radius: 4px;
		}
		.glyphicon-eye-open,
		.glyphicon-eye-close {
			color: #fff;
		}
	}

	.btn.btn-default {
		background-color: $massia_gestion;
		border-radius: 4px;
		border: none;
		margin-top: 20px;
		color: white;
	}

	// **************************************************
	.navbar-brand {
		img {
			height: 30px;
		}
	}

	&-aside {
		position: relative;
		height: 100%;
		min-height: 750px;
		margin-right: auto;
		background-color: white;

		@media (min-width: #{$screen-sm-min}) {
			width: 750px;
		}
	}

	&-main {
		overflow-y: auto;
		overflow-x: hidden;
		padding: 20px;

		@media (min-width: #{$screen-sm-min}) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&-navbar {
		border-radius: 0;
		background: white;
		border: 0;

		.nav > li > a:focus,
		.nav > li > a:hover {
			background-color: transparent;
		}

		.navbar-nav {
			float: left;
			margin: 0;
			> li {
				float: left;
			}
		}

		.flag-icon {
			height: 16px;
			margin: 2px 0;
			display: block;
		}

		.navbar-right {
			float: right !important;
			margin-right: 0;
		}
	}

	&-headings {
		margin-bottom: 30px;
	}

	&-logo {
		margin-top: 0;
		img {
			height: 40px;

			@media (min-width: #{$screen-sm-min}) {
				height: 72px;
			}
		}
	}

	&-sublogo {
		text-align: center;
		margin-top: 30px;
		margin-bottom: 10px;

		@media (min-width: #{$screen-sm-min}) {
			margin-bottom: 60px;
		}

		&-item {
			opacity: 0.25;
			cursor: pointer;
			transition: opacity 0.2s;

			&.active,
			&:hover {
				opacity: 1;
			}

			@media (min-width: #{$screen-sm-min}) {
				/* width:125px;
        height: 125px; */
			}
		}
	}

	&-title {
		@media (max-height: #{$screen-sm-max}) {
			display: none;
		}
	}

	&-form {
		form {
			position: relative;
		}

		label {
			&:first-letter {
				text-transform: uppercase;
			}
		}

		.btn {
			margin-top: 28px;
		}

		.ac-loading-wrapper-center {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			// background-color: rgba(white, 0.6);
			z-index: 999;
		}
	}

	&-footer {
		color: $gray;
		background: $body-bg;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 10px 0;

		.list-social-icon {
			text-align: right;
		}

		@media (max-height: $screen-sm-min) {
			font-size: 10px;

			.list-social-icon {
				text-align: center;
			}
		}

		p {
			margin-bottom: 2px;
		}

		.social-icon {
			@media (min-height: $screen-sm-min) {
				margin: 40px 4px 0 4px;
			}

			img {
				height: 20px;
			}
		}
	}

	.arcade-title {
		color: $gray;
		margin-top: 0;
		display: none;

		@media (min-width: #{$screen-sm-min}) {
			display: block;
		}
	}
}
