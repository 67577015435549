.ui-select {
  &.ui-select-bootstrap > .ui-select-no-choice {
    padding: 3px 0 3px 20px;
  }

  .ui-select-toggle {
    background-color: transparent !important;
  }
}

.ui-select-bootstrap .ui-select-choices-row {
  &.active a,
  a {
    &,
    &:focus,
    &:hover {
      background-color: transparent !important;
    }
  }

  &:hover {
    a {
      &,
      &:focus,
      &:hover {
        color: #fff !important;
      }
    }
  }
}