@import './massia-style/flexbox.scss';
@import './massia-style/gridbox.scss';
@import './massia-style/mixins.scss';
@import './massia-style/colorpicker.scss';
@import './massia-style/electron.scss';
@import './massia-style/isteven.scss';
@import './massia-style/massia-page-loading.scss';
@import './massia-style/massia-print-modal.scss';
@import './massia-style/massia-table.scss';
@import './massia-style/massia-calendar.scss';
@import './massia-style/massia-accordion.scss';
@import './massia-style/massia-switch.scss';
@import './massia-style/massia-slider.scss';
@import './massia-style/portail.scss';
@import './massia-style/massia-fieldset.scss';
@import './massia-style/massia-tree.scss';
@import './massia-style/massia-navigation.scss';
@import './massia-style/massia.multi.select.scss';
@import './massia-style/massia-context-menu.scss';
@import './massia-style/modal.scss';

massia-slider-detail {
    .control-label {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

// SCSS Valable pour toute les application et ainsi pourvoir utiliser les variable à un seul endroit
massia-application {
    .ui-custom-grid {
        background: white;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
    }

    .grid-cell-right {
        text-align: end;
    }

    .ui-grid-filter-custom {
        display: flex;
        .ui-grid-filter-container {
            margin: 4px;
            padding: 0px;
            position: relative;
            border-radius: 3px;
            overflow: hidden;
        }
    }

    .form-footer {
        border-top: 1px solid $primary;
    }

    .form-dynamic-disabled {
        background-color: rgba($color: #484848, $alpha: 0.14) !important;
    }

    .bg-gradient-primary {
        @include gradient(background, $primary);
    }

    .bg-gradient-danger {
        @include gradient(background, $brand-danger);
    }

    .bg-primary {
        .ui-grid-filter-input {
            color: black;
            font-weight: normal;
        }
    }

    .main-content {
        &::-webkit-scrollbar {
            width: 11px; /* Remove scrollbar space */
            background: none; /* Optional: just make scrollbar invisible */
            border: 2px solid #fff;
            z-index: 3000;
        }
        &::-webkit-scrollbar-track {
            border: 2px solid #fff;
            z-index: 3000;
        }
        /* Optional: color of position indicator */
        &::-webkit-scrollbar-thumb {
            border: 2px solid #fff;
            border-radius: 50px;
            background: $brand-primary;
            z-index: 3000;
        }
    }

    .mas-scrollbar {
        &::-webkit-scrollbar {
            width: 11px; /* Remove scrollbar space */
            height: 10px;
            background: none; /* Optional: just make scrollbar invisible */
            border: 2px solid #fff;
            z-index: 3000;
        }
        &::-webkit-scrollbar-track {
            border: 2px solid #fff;
            z-index: 3000;
        }
        /* Optional: color of position indicator */
        &::-webkit-scrollbar-thumb {
            border: 2px solid #fff;
            border-radius: 50px;
            background: $brand-primary;
            z-index: 3000;
        }
    }

    .slider-content {
        &::-webkit-scrollbar {
            width: 5px; /* Remove scrollbar space */
            background: none; /* Optional: just make scrollbar invisible */
            z-index: 3000;
        }
        /* Optional: color of position indicator */
        &::-webkit-scrollbar-thumb {
            border-radius: 15px;
            background: $brand-primary;
            z-index: 3000;
        }
    }

    .abs-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .bg-semi-transparent {
        background-color: rgba($color: white, $alpha: 0.6);
    }

    /* Drag and drop color */
    .table-row-ui-placeholder {
        background: $brand-primary !important;
    }

    .loading-center {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
    }

    .event-text {
        padding: 5px;
    }

    //Render event in calendar
    .show-event {
        display: block;
    }

    .hide-event {
        display: none;
    }

    .empty-icon {
        font-size: 50px;
    }
    //Fin massia application

    $square-size: 15px;
    @mixin realised($color) {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 5px;
        z-index: 2000;
        width: $square-size;
        height: $square-size;
        background: $color;
        border-radius: 50%;
        border: 2px solid white;
    }

    .modal-content {
        border: 0;
    }

    .realised-checking {
        @include realised(orange);
    }

    .realised-true {
        @include realised(limegreen);
    }

    .realised-false {
        @include realised(red);
    }

    .main-layout {
        &.ng-enter {
            transition: all 0.25s;
            opacity: 0;
        }
        &.ng-enter-active {
            opacity: 1;
        }
    }

    .ui-grid-menu {
        overflow: auto;
        padding: 0px 0px 20px 10px;
    }

    .icon-synth-error {
        &.ng-enter {
            transition: all 0.25s;
            transform: scaleX(0);
            opacity: 0;
        }
        &.ng-enter-active {
            opacity: 1;
            transform: scaleX(1);
        }

        &.ng-leave {
            transition: all 0.25s;
            transform: scaleX(1);
            opacity: 1;
        }
        &.ng-leave-active {
            opacity: 0;
            transform: scaleX(0);
        }
    }

    //Graphique fullscreen
    .fullscreen-graph {        
        .btn-fullscreen {
            position: absolute;
            top: 0;
            right: 0;
            transition: all 0.1s linear;

            &:hover {
                transform: scale(1.3);
            }
        }
    }

    .fullscreen-active {
        z-index: 1000000;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: white;
    }
    //Fin Graphique fullscreen

    .mas-badge {
        transition: all 0.2s;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        border-radius: 10px;
        padding: 0px 5px;
        background-color: red;
        color: white;
        min-width: 20px;
        height: 20px;
        font-size: 12px;

        &-close {
            position: absolute;
            transform: translateY(0);
            top: 0;
            right: 0;
        }
    }

    .border-primary {
        border: 2px solid $primary;
        border-radius: 5px;
        padding: 0;
        margin-left: 10px;
    }

    .pagination-page {
        color: $primary;
        &.active {
            a {
                background: $primary;
                color: white;
            }
        }
    }
    

    .panel {
        background: #fff;
    }

    // .modal-backdrop {
    //     z-index: 10000000 !important;
    // }

    // .modal {
    //     z-index: 10000001 !important;
    // }

    .stepper {
        transition: all 0.3s;
        max-height: 0px;
        transform: translateX(-100%);
        transition-delay: 0s;
        opacity: 0;
        &.active {
            max-height: 2000px;
            transform: translateX(0);
            transition-delay: 0.25s;
            opacity: 1;
        }
    }

    .drop-column {
        min-width: 30px;
    }

    .drag-hover-column {
        background-color: lighten($color: $brand-primary, $amount: 15%) !important;
    }

    .column-synth-input {
        background: rgba(0, 0, 0, 0);
        border: none;
        outline: none;
    }

    .synthese-critere {
        &.ng-enter {
            transition: all 0.25s;
            transform-origin: left;
            transform: scaleX(0);
            opacity: 0;
        }
        &.ng-enter-active {
            opacity: 1;
            transform: scaleX(1);
        }

        &.ng-leave {
            transition: all 0.25s;
            transform-origin: left;
            transform: scaleX(1);
            opacity: 1;
        }
        &.ng-leave-active {
            opacity: 0;
            transform: scaleX(0);
        }
    }

    .synthesis-separator {
        border: 1px solid #b7b7b7;
    }

    .loading-synth {
        position: absolute;
        margin: 5px;
        border-radius: 10px;
        border: 1px solid #b4b4b4;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($color: white, $alpha: 0.9);
        transition: all 0.3s;
        &.ng-enter {
            opacity: 0;
        }
        &.ng-enter-active {
            opacity: 1;
        }

        &.ng-leave {
            opacity: 1;
        }
        &.ng-leave-active {
            opacity: 0;
        }

        .rotate-synth-cog {
            color: $brand-primary;
            animation: spin 2s linear infinite;

            &-i {
                color: $brand-primary;
                animation: spin 2s linear infinite reverse;
            }
        }
    }

    $number_of_dot: 3;
    @for $i from 1 through $number_of_dot {
        .loading-dot:nth-child(#{$i}) {
            transition: all 0.3s;
            padding: 0px 5px;
            font-size: 48px;
            animation-duration: 2s;
            animation-delay: $i * 0.5s;
            animation-name: bouncingdot;
            animation-iteration-count: infinite;
        }
    }

    .bg-offre-a-valider {
        background-color: #ff5917 !important;
        color: black;

        opacity: 1;
    }
}
