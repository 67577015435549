.pcr-app {
  .pcr-interaction {
    display: block;

    .pcr-type {
      &.active {
        background: $brand-primary;
        color: #fff;
      }
    }
    input {
      &.pcr-save {
        background: $brand-primary;
        color: #fff;
      }
      &.pcr-cancel {
        color: #fff;
      }
      &:focus {
        box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.85),
          0 0 0 3px rgba($color: $brand-primary, $alpha: 0.75);
      }
    }
  }
}