.dropdown-menu {
    .multiSelect {
        position: initial !important;
    }
}

isteven-multi-select {
    @keyframes slide-in {
        0% {
            transform: scaleY(0);
        }
        100% {
            transform: scaleY(1);
        }
    }

    @keyframes slide-out {
        0% {
            transform: scaleY(1);
        }
        100% {
            transform: scaleY(0);
        }
    }

    .show {
        transform: scaleY(1);
        transform-origin: 50% 0%;
        animation: slide-in 0.15s;
        z-index: 60000;
    }

    .hide {
        transform: scaleY(0);
        display: block !important;
    }

    .multiSelect {
        .multiSelectItem {
            display: flex;
            justify-content: space-between;
            border: none;
        }

        button {
            display: inline-block !important;
        }
        .custom-checkmark {
            align-self: center;
            margin-right: 10px;
            color: green;
            transform: rotate(0deg);
            transition: all 0.2s;

            &.ng-hide {
                align-self: center;
                margin-right: 10px;
                transform: rotate(-90deg);
            }
        }

        ::-webkit-scrollbar {
            width: 11px; /* Remove scrollbar space */
            background: none; /* Optional: just make scrollbar invisible */
            border: 2px solid #fff;
        }
        ::-webkit-scrollbar-track {
            border: 2px solid #fff;
        }
        /* Optional: color of position indicator */
        ::-webkit-scrollbar-thumb {
            border: 2px solid #fff;
            border-radius: 50px;
            background: $brand-primary;
        }
    }
}
