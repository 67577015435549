.breadcrumb-container {
  font-size: $font-size-h4;
  padding-left: 15px;
  padding-right: 15px;

  .breadcrumb {
    padding-top: 0;
    background-color: transparent;
    border-bottom: solid 1px #b7b7b7;
    padding-bottom: 10px;
    margin-bottom: 0;

    > li {
      cursor:default;
      &.active * {
        color: $gray-darker;
        font-weight: bold;
      }

      a:hover,
      a:focus {
        cursor: pointer;
        color: $gray;
      }

      * {
        color: $gray-darker;
      }

      + li:before {
        @extend .glyphicon;
        @extend .glyphicon-menu-right;
        font-size: $font-size-h6;
        top: 0;
        color: $gray-darker;
      }
    }
  }
}