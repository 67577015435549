@import "~ui-select/dist/select";

/*** UI-SELECT OVERRIDE ***/
.input-group .ng-hide + .ui-select-container .ui-select-toggle,
.input-group .ng-hide + .ui-select-container.ui-select-multiple {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ui-select-container {
  min-height: 34px;

  &[append-to-body~=true] {
    z-index: 2000 !important; /* pour être au dessus des modales */
  }

  + .input-group-btn .glyphicon.glyphicon-remove {
    vertical-align: middle;
    top: 0;
  }

  input[type=text]::-ms-clear {
    display: none;
  }
}

.ui-select-toggle {
  .btn {
    text-align: left;
  }
}

.ui-select-match-item,
.ui-select-match-text,
.ui-select-choices-row div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.pointer-events-none.ui-select-multiple,
div.pointer-events-none .ui-select-match .ui-select-toggle {
  //background-color: #eee;
  opacity: 1;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

div.pointer-events-none input.ui-select-search {
  pointer-events: none !important;
}