@font-face {
  font-family: 'massia-icons';
  src:  url('fonts/massia-icons.eot?v5s1te');
  src:  url('fonts/massia-icons.eot?v5s1te#iefix') format('embedded-opentype'),
    url('fonts/massia-icons.ttf?v5s1te') format('truetype'),
    url('fonts/massia-icons.woff?v5s1te') format('woff'),
    url('fonts/massia-icons.svg?v5s1te#massia-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="massia-icon-"], [class*=" massia-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'massia-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.massia-icon-menu:before {
  content: "\e900";
}
.massia-icon-menu1:before {
  content: "\e901";
}
.massia-icon-menu11:before {
  content: "\e94f";
}
.massia-icon-dumper2:before {
  content: "\e975";
}
.massia-icon-dumper:before {
  content: "\e974";
}
.massia-icon-element_branch2:before {
  content: "\e915";
  color: #fff;
}
.massia-icon-elements_branch:before {
  content: "\e91a";
  color: #fff;
}
.massia-icon-elements_cascade:before {
  content: "\e917";
  color: #fff;
}
.massia-icon-elements_hierarchy:before {
  content: "\e918";
  color: #fff;
}
.massia-icon-elements_tree:before {
  content: "\e919";
  color: #fff;
}
.massia-icon-gearwheel3:before {
  content: "\e924";
  color: #fff;
}
.massia-icon-home:before {
  content: "\e91b";
  color: #fff;
}
.massia-icon-location_pin2:before {
  content: "\e91c";
  color: #fff;
}
.massia-icon-magnifying_glass:before {
  content: "\e91d";
  color: #fff;
}
.massia-icon-star3:before {
  content: "\e91e";
  color: #fff;
}
.massia-icon-gearwheel:before {
  content: "\e90b";
  color: #fff;
}
.massia-icon-home2:before {
  content: "\e90c";
  color: #fff;
}
.massia-icon-location_pin:before {
  content: "\e90d";
  color: #fff;
}
.massia-icon-magnifying_glass2:before {
  content: "\e90e";
  color: #fff;
}
.massia-icon-media_eject1:before {
  content: "\e93d";
  color: #fff;
}
.massia-icon-nav_undo:before {
  content: "\e939";
  color: #fff;
}
.massia-icon-selection_delete2:before {
  content: "\e943";
  color: #fff;
}
.massia-icon-star2:before {
  content: "\e90f";
  color: #fff;
}
.massia-icon-undo:before {
  content: "\e93b";
  color: #fff;
}
.massia-icon-checks:before {
  content: "\e941";
  color: #fff;
}
.massia-icon-close:before {
  content: "\e908";
  color: #fff;
}
.massia-icon-delivery_truck:before {
  content: "\e907";
  color: #fff;
}
.massia-icon-door_exit:before {
  content: "\e906";
  color: #fff;
}
.massia-icon-elements_selection:before {
  content: "\e909";
  color: #fff;
}
.massia-icon-gearwheel2:before {
  content: "\e904";
  color: #fff;
}
.massia-icon-home22:before {
  content: "\e902";
  color: #fff;
}
.massia-icon-location_pin22:before {
  content: "\e903";
  color: #fff;
}
.massia-icon-magnifying_glass22:before {
  content: "\e905";
  color: #fff;
}
.massia-icon-selection_delete:before {
  content: "\e90a";
  color: #fff;
}
.massia-icon-star1:before {
  content: "\e945";
  color: #fff;
}
.massia-icon-tree:before {
  content: "\e9bc";
}
.massia-icon-certification:before {
  content: "\e94a";
}
.massia-icon-certificate:before {
  content: "\e94e";
}
.massia-icon-chart_column:before {
  content: "\e91f";
  color: #fff;
}
.massia-icon-cloud1:before {
  content: "\e921";
  color: #fff;
}
.massia-icon-cloud_sun:before {
  content: "\e925";
  color: #fff;
}
.massia-icon-lock:before {
  content: "\e92a";
  color: #fff;
}
.massia-icon-sun:before {
  content: "\e928";
  color: #fff;
}
.massia-icon-lab1:before {
  content: "\e9ab";
}
.massia-icon-list-numbered1:before {
  content: "\e9ba";
}
.massia-icon-info1:before {
  content: "\ea0d";
}
.massia-icon-table21:before {
  content: "\ea72";
}
.massia-icon-element_branch211:before {
  content: "\e963";
  color: #fff;
}
.massia-icon-elements_branch11:before {
  content: "\e964";
  color: #fff;
}
.massia-icon-elements_cascade11:before {
  content: "\e965";
  color: #fff;
}
.massia-icon-elements_hierarchy11:before {
  content: "\e966";
  color: #fff;
}
.massia-icon-elements_tree11:before {
  content: "\e967";
  color: #fff;
}
.massia-icon-gearwheel311:before {
  content: "\e968";
  color: #fff;
}
.massia-icon-home11:before {
  content: "\e969";
  color: #fff;
}
.massia-icon-location_pin211:before {
  content: "\e96a";
  color: #fff;
}
.massia-icon-magnifying_glass11:before {
  content: "\e96b";
  color: #fff;
}
.massia-icon-star311:before {
  content: "\e96c";
  color: #fff;
}
.massia-icon-gearwheel1:before {
  content: "\e910";
  color: #fff;
}
.massia-icon-home211:before {
  content: "\e96d";
  color: #fff;
}
.massia-icon-location_pin11:before {
  content: "\e96e";
  color: #fff;
}
.massia-icon-magnifying_glass211:before {
  content: "\e96f";
  color: #fff;
}
.massia-icon-media_eject11:before {
  content: "\e970";
  color: #fff;
}
.massia-icon-nav_undo11:before {
  content: "\e962";
  color: #fff;
}
.massia-icon-selection_delete211:before {
  content: "\e961";
  color: #fff;
}
.massia-icon-star211:before {
  content: "\e960";
  color: #fff;
}
.massia-icon-undo11:before {
  content: "\e95f";
  color: #fff;
}
.massia-icon-checks11:before {
  content: "\e95e";
  color: #fff;
}
.massia-icon-close11:before {
  content: "\e95d";
  color: #fff;
}
.massia-icon-delivery_truck11:before {
  content: "\e95c";
  color: #fff;
}
.massia-icon-door_exit11:before {
  content: "\e95b";
  color: #fff;
}
.massia-icon-elements_selection11:before {
  content: "\e95a";
  color: #fff;
}
.massia-icon-gearwheel211:before {
  content: "\e959";
  color: #fff;
}
.massia-icon-home2211:before {
  content: "\e958";
  color: #fff;
}
.massia-icon-location_pin2211:before {
  content: "\e957";
  color: #fff;
}
.massia-icon-magnifying_glass2211:before {
  content: "\e956";
  color: #fff;
}
.massia-icon-selection_delete11:before {
  content: "\e955";
  color: #fff;
}
.massia-icon-star11:before {
  content: "\e954";
  color: #fff;
}
.massia-icon-tree1:before {
  content: "\e9bd";
}
.massia-icon-certification1:before {
  content: "\e94b";
}
.massia-icon-certificate1:before {
  content: "\e950";
}
.massia-icon-chart_column11:before {
  content: "\e971";
  color: #fff;
}
.massia-icon-cloud11:before {
  content: "\e922";
  color: #fff;
}
.massia-icon-cloud_sun1:before {
  content: "\e926";
  color: #fff;
}
.massia-icon-pipette_test:before {
  content: "\e972";
  color: #fff;
}
.massia-icon-lock1:before {
  content: "\e92b";
  color: #fff;
}
.massia-icon-sun1:before {
  content: "\e929";
  color: #fff;
}
.massia-icon-tables:before {
  content: "\e973";
  color: #fff;
}
.massia-icon-lab:before {
  content: "\e9aa";
}
.massia-icon-info:before {
  content: "\ea0c";
}
.massia-icon-table2:before {
  content: "\ea71";
}
.massia-icon-list-numbered:before {
  content: "\e9b9";
}
.massia-icon-element_branch21:before {
  content: "\e916";
  color: #fff;
}
.massia-icon-elements_branch1:before {
  content: "\e92c";
  color: #fff;
}
.massia-icon-elements_cascade1:before {
  content: "\e92d";
  color: #fff;
}
.massia-icon-elements_hierarchy1:before {
  content: "\e92e";
  color: #fff;
}
.massia-icon-elements_tree1:before {
  content: "\e92f";
  color: #fff;
}
.massia-icon-gearwheel31:before {
  content: "\e930";
  color: #fff;
}
.massia-icon-home1:before {
  content: "\e931";
  color: #fff;
}
.massia-icon-location_pin21:before {
  content: "\e932";
  color: #fff;
}
.massia-icon-magnifying_glass1:before {
  content: "\e933";
  color: #fff;
}
.massia-icon-star31:before {
  content: "\e934";
  color: #fff;
}
.massia-icon-gearwheel11:before {
  content: "\e911";
  color: #fff;
}
.massia-icon-home21:before {
  content: "\e912";
  color: #fff;
}
.massia-icon-location_pin1:before {
  content: "\e913";
  color: #fff;
}
.massia-icon-magnifying_glass21:before {
  content: "\e914";
  color: #fff;
}
.massia-icon-media_eject:before {
  content: "\e935";
  color: #fff;
}
.massia-icon-nav_undo1:before {
  content: "\e946";
  color: #fff;
}
.massia-icon-selection_delete21:before {
  content: "\e947";
  color: #fff;
}
.massia-icon-star21:before {
  content: "\e948";
  color: #fff;
}
.massia-icon-undo1:before {
  content: "\e949";
  color: #fff;
}
.massia-icon-checks1:before {
  content: "\e942";
  color: #fff;
}
.massia-icon-close1:before {
  content: "\e953";
  color: #fff;
}
.massia-icon-delivery_truck1:before {
  content: "\e944";
  color: #fff;
}
.massia-icon-door_exit1:before {
  content: "\e940";
  color: #fff;
}
.massia-icon-elements_selection1:before {
  content: "\e93f";
  color: #fff;
}
.massia-icon-gearwheel21:before {
  content: "\e93e";
  color: #fff;
}
.massia-icon-home221:before {
  content: "\e93c";
  color: #fff;
}
.massia-icon-location_pin221:before {
  content: "\e93a";
  color: #fff;
}
.massia-icon-magnifying_glass221:before {
  content: "\e938";
  color: #fff;
}
.massia-icon-selection_delete1:before {
  content: "\e937";
  color: #fff;
}
.massia-icon-star:before {
  content: "\e936";
  color: #fff;
}
.massia-icon-tree11:before {
  content: "\e9be";
}
.massia-icon-certification11:before {
  content: "\e94c";
}
.massia-icon-certificate11:before {
  content: "\e951";
}
.massia-icon-chart_column1:before {
  content: "\e923";
  color: #fff;
}
.massia-icon-cloud:before {
  content: "\e920";
  color: #fff;
}
.massia-icon-cloud_sun11:before {
  content: "\e927";
  color: #fff;
}
.massia-icon-lock11:before {
  content: "\e952";
  color: #fff;
}
.massia-icon-sun11:before {
  content: "\e94d";
  color: #fff;
}
.massia-icon-file-excel:before {
  content: "\eae2";
}
