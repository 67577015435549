.modern-modal {
    &.ng-enter {
        transition: all 0.25s;
        transform: scale(0);
        opacity: 0;
    }
    &.ng-enter-active {
        opacity: 1;
        transform: scale(1);
    }

    &.ng-leave {
        transition: all 0.25s;
        transform: scale(1);
        opacity: 1;
    }
    &.ng-leave-active {
        opacity: 0;
        transform: scale(0);
    }
    .modal-dialog {
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .modal-content {
            border-radius: 10px;
            height: 100%;
            overflow: hidden;
        }
    }

    .modal-lg {
        width: 80%;
        height: 80%;
    }

    .modal-80 {
        width: 80%;
        height: 80%;
    }

    .popover {
        width: auto;
        max-width: none;
    }

    .form-footer {
        border-top: none;
    }
}
