
.domain-right-container {
    button {
        outline: 0;
    }
    .glyphicon-ok {
        color: green;

        &.ng-enter {
            transition: all 0.25s;
            transform: rotate(-90deg);
        }

        &.ng-enter-active {
            transform: rotate(0deg);
        }
    }

    .glyphicon-remove {
        color: red;

        &.ng-enter {
            transition: all 0.25s;
            transform: rotate(-90deg);
        }

        &.ng-enter-active {
            transform: rotate(0deg);
        }
    }
}

portail-home {
    .c-pointer {
        cursor: pointer;
    }

    .card-icon-br {
        position: absolute;
        font-size: 10rem;
        top: 40px;
        left: -10px;
        opacity: 0.4;
    }

    .panel {
        border-radius: 5px;

        tbody {
            tr {
                transition: all 0.15s;
                &:hover {
                    background-color: lighten(grey, 25%) !important;
                }
            }

            .btn-link {
                &:hover {
                    .glyphicon {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .inline {
        display: inline;
    }

    .bold {
        font-weight: bold;
    }

    .thead-wrapper {
        .floatThead-wrapper {
            height: 100%;
        }
    }

    .no-margin {
        margin: 0;
    }


    .text-primary-gradient {
        @include gradient(background, $primary);
        color: transparent;
        -webkit-background-clip: text;
    }

    .table-bg-gradient {
        tr {
            $base: darken($primary, 10%);
            th {
                font-weight: bold;
                @for $i from 1 through 30 {
                    $color1: $base;
                    $color2: lighten($base, $i * 2%);
                    &:nth-child(#{$i}) {
                        @include little-gradient(background-color, $color1, $color2);
                    }
                    $base: $color2;
                }
            }
        }
    }

    #nav-portail {
        transition: width 0.25s;
    }

    #ui-portail {
        transition: margin 0.25s;
    }

    .bg-gradient {
        /* background-image: linear-gradient(to right top, #051937, #02395d, #005d82, #0084a4, #00acc1); */
        @include gradient(background, $primary);

        &-warning {
            @include gradient(background, $brand-warning);
        }

        &-danger {
            @include gradient(background, $brand-danger);
            * {
                color: white;
            }
        }

        * {
            color: white;
        }
    }

    .close-nav {
        transition: all 0.25s;
    }

    .nav-info {
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .nav-list {
        list-style: none;
        padding: 0;
        width: 220px;
        &-item {
            border-radius: 2px;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition-property: background, color;
            transition-duration: 0.1s;
            transition-timing-function: ease-in;

            &-icon {
                font-size: 2rem;
            }

            .hover {
                padding: 5px 5px;
                &:hover {
                    * {
                        color: white;
                    }
                    @include gradient(background, $primary);
                    border-radius: 2px;
                }
            }

            &-active {
                * {
                    color: white;
                }
                @include gradient(background, $primary);
                border-radius: 2px;
            }

            &-link {
                font-weight: bold;
            }

            .sub-collapse {
                transition: all 0.2s;
                &.ng-enter {
                    transition: all 0.3s ease;
                    transform: scaleY(0);
                    transform-origin: top;
                }

                &.ng-enter.ng-enter-active {
                    transform: scaleY(1);
                }

                &.ng-leave {
                    transition: all 0.3s ease;
                    transform: scaleY(1);
                    transform-origin: top;
                }

                &.ng-leave.ng-leave-active {
                    transform: scaleY(0);
                }
            }

            .rotate {
                transform: translateY(-50%) rotate(180deg) !important;
            }

            .down-arrow-sub {
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(0deg);
                transition: all 0.3s ease-in-out;
            }

            .sub-nav {
                list-style: none;
                font-size: 1.4rem;
                padding-left: 10px;

                .sub-nav-item {
                    transition: all 0.2s;
                    padding: 5px;

                    &-icon {
                        font-size: 1.4rem;
                    }

                    &:hover {
                        color: #484848;
                    }
                }
            }
        }
    }

    .portail-main {
        #slider-container {
            height: 100%;
            overflow: auto;
        }

        /* .liste-footer {
            position: absolute;
            &.ng-enter {
                transition: all 0.25s;
                opacity: 0;
            }

            &.ng-enter-active {
                opacity: 1;
            }
        } */
        &.ng-enter {
            transition: all 0.25s;
            transform: translateX(200%);
            opacity: 0;
        }
        &.ng-enter-active {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

.portail-stats-list {
    .zoom-in {
        opacity: 0;
        animation: slide-from-top 0.3s ease-out forwards;
        @include stagger(100ms, 50);
    }
}

preview-login, preview-home {

    .row-end {
        text-align: end;
    }

    .page{
        height: 60vh;
        width: 60vw;
    }
}
preview-login {

    .page{
        display: flex;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

    }
    .card-login {
        width: 80%;
        height: 70%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.6) 50%,
          rgba(255, 255, 255, 1) 100%
        );
       
        .login-client {
            //* partie info client (nom entreprise / logo)
            position: relative;
            height: 100%;
      
            .logo {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 5px;
              height: 150px;
      
              .block {
                //* block animation
                width: 0%;
                height: inherit;
                background: #50af9c;
                position: absolute;
                animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
                display: flex;
              }
              img {
                animation: mainFadeIn 2.5s forwards;
                animation-delay: 1.4s;
                opacity: 0;
                height: 85px;
                margin: auto;
              }
            }
            .name {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
      
              .block {
                //* block animation
                width: 0%;
                height: inherit;
                background: #547284;
                position: absolute;
                animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
                display: flex;
              }
      
              img {
                color: black;
                -webkit-animation: mainFadeIn 2s forwards;
                -o-animation: mainFadeIn 2s forwards;
                animation: mainFadeIn 2.5s forwards;
                animation-delay: 1.4s;
                opacity: 0;
                display: flex;
                align-items: baseline;
                position: relative;
              }
            }
          }
      
          .login-form {
            //* formulaire de connexion
            height: 100%;
          }

      }
      @keyframes mainBlock {
        0% {
          width: 0%;
          left: 0;
        }
        50% {
          width: 100%;
          left: 0;
        }
        100% {
          width: 1%;
          left: 100%;
        }
      }
      @keyframes mainFadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      
      //? Animation logo
      @keyframes secBlock {
        0% {
          width: 0%;
          right: 0;
        }
        50% {
          width: 100%;
          right: 0;
        }
        100% {
          width: 1%;
          left: 0;
        }
      }
      @keyframes secFadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 0.7;
        }
      }
      
}

preview-home {
    .menu {
        background-color: rgba(lightgray, 0.5);
    }
    .sidebar {
        .item {
            
              min-width: 190px;
                color: white;
            &:not(:first-child):not(.logout) {
              border-bottom: solid 1px rgba(0, 0, 0, 0.09);
            }
            &:hover:not(.close-menu) {
              background-color: rgba(0, 0, 0, 0.09);
            }

            &.close-menu {
                justify-content: flex-end;
                span{
                    font-size: 20px;
                    margin-right: 3px;
                }
            }
          }
      
       
          .contact {
              color: white;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            position: absolute;
            bottom: 80px;
            border-style: solid;
            border-width: 1px;
            border-right: none;
            border-left: none;
            width: 190px;
            padding-bottom: 10px;

            img{
                height: 25px;
            }
          }
          .logout {
            position: absolute;
            bottom: 3%;
            font-weight: bold;
            width: 190px
          }
        }

    .home-toolbar {
        font-size: 10px;
        display: flex;
        align-content: center;
        height: auto;
        .welcome-span {
          font-size: 1em;
          font-weight: 500;
        }
        .lastLogin-span {
          font-style: italic;
          font-size: 0.9em;
          font-weight: lighter;
        }

        .user {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.5em;
            padding-right: 5px;
        }

        .client {
            font-size: 2em;
            font-weight: 500;
        }
    }

    .tile {
        height: 6.5em;
        font-size: 20px !important;
        font-weight: 400;
        text-align: center;
        background-color: white;
        border-radius: 0.2em;
        border: none;
        height: max-content;

       
      
            .access {
              padding: 5%;
              border-width: 1px;
              border-style: solid;
              border-radius: 1.25em;
              margin-top: 10px;
            }
            
          .tile-doc {
              color: white;
              margin-top: 15px;
              width: 90%;
              margin-right: auto;
              margin-left: auto;
              text-align: center;
              margin-bottom: 10px;
              .title {
                font-size: 16px;
                width: 100%;
                padding-top: 5px;
                padding-bottom: 5px;
              }
              .badge{
                  border-radius: 1em !important;
                  min-width: 10px !important;
                    padding: 3px 9px !important;
                    position: relative;
                    right: -25px;
                    top: -13px;
                
              }
          }
       
    }
}
