@import './variable';
@import './function';
@import './animations';

.mas-d-flex {
    display: flex;
}

@each $key, $value in $justify-content {
    .mas-jc-#{$key} {
        justify-content: $value;
    }
}

@for $i from -50 through 100 {
    .table-br-#{$i} {
        border-collapse: separate;
        border-radius: 1px * $i;
        overflow: hidden;
    }

    .mas-br-#{$i} {
        border-radius: 1px * $i;
    }

    .mas-p-#{$i} {
        padding: 1px * $i;
    }

    .mas-m-#{$i} {
        margin: 1px * $i;
    }

    .mas-fs-#{$i} {
        font-size: 1px * $i;
    }

    .mas-gap-#{$i} {
        gap: 1px * $i;
    }

    @each $short, $value in $height-width {
        .mas-w#{$short}-#{$i} {
            width: $value * $i;
        }

        .mas-h#{$short}-#{$i} {
            height: $value * $i;
        }
    }

    @each $short, $property in $margin-padding-shortcut {
        .mas-p#{$short}-#{$i} {
            padding-#{$property}: 1px * $i;
        }

        .mas-m#{$short}-#{$i} {
            margin-#{$property}: 1px * $i;
        }

        .mas-m-#{$property}-#{$i} {
            margin-#{$property}: 1px * $i;
        }

        .mas-p-#{$property}-#{$i} {
            padding-#{$property}: 1px * $i;
        }
    }
}

@each $key, $value in $align-item {
    .mas-ai-#{$key} {
        align-items: $value;
    }
}

@each $key, $value in $positions {
    .mas#{$key} {
        position: $value;
    }
}

@each $modifier, $breakpoint in $map-col-props {
    @include create-col-classes($modifier, $col-size, $breakpoint);
    @include create-hidden-classes($modifier, $breakpoint);
}

.mas-col-fill {
    flex: 1 1 auto;
    min-width: 0;
}

.mas-col-fit {
    flex: 0 0 auto;
}

.mas-container {
    width: 100%;
    margin: auto;
}

.mas-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.mas-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}
