.hierarchie {
  &-form {
    .niveau-list {
      margin: 0;
      padding: 0;
    }

    .niveau-item {
      padding: 2px 0;

      .form-group {
        width: 100%;
      }

      .control-label {
        width: 50%;
        vertical-align: top;
        padding-top: 5px;
        padding-right: 10px;

        .btn {
          margin-right: 8px;
        }
      }

      .form-control {
        width: 50%;
      }
    }
  }
}