@for $i from 0 to 100 {
    .g-gap-#{$i} {
        gap: 1px * $i;
    }
    .g-container-#{$i} {
        display: grid;
        grid-template-columns: repeat($i, 1fr);
    }
    .g-container-#{$i}-auto {
        display: grid;
        grid-template-columns: repeat($i, auto);
    }
}

.g-container-auto {
    display: grid;
    grid-template-columns: auto;
}
