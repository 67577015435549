[uib-accordion-header] {
  vertical-align: middle;

  span + span {
    padding-left: calc($padding-base-horizontal / 2);
  }

  .glyphicon {
    top: 2px;
  }
}

.accordion-toggle {
  display: block;
}