massia-multi-select {
    .fake-input {
        height: auto;
        padding: 2px;
        min-height: 34px;
    }

    .disabled-line {
        span {
            color: lighten($color: grey, $amount: 10) !important;
        }
    }

    .massia-popup-wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 1000;
        display: none;
        background-color: #f9f9f9;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        padding: 5px;

        .message {
            padding: 10px 20px;
            border-bottom: 1px solid #ddd;
            color: #868686;
        }

        .dropdown-menu {
            position: static;
            float: none;
            display: block;
            min-width: 160px;
            background-color: transparent;
            border: none;
            border-radius: 0;
            box-shadow: none;

            .item-wrapper {
                padding: 5px;
            }
        }
    }

    .input-container {
        .mas-col-fill {
            div:first-child {
                position: initial !important;
                width: 100%;
                input:first-child {
                    width: auto;
                    display: none;
                }
            }
        }
        input {
            top: auto !important;
            left: auto !important;
            width: 100%;
            outline: none !important;
            border: none;
            background: transparent !important;
        }
        .output-item {
            background: white;
            max-width: 100%;
            .text-ellipsis {
                color: $primary;
                font-style: italic;
            }
            &.bg-primary {
                background: $primary;
                .btn {
                    color: white;
                }
            }
            border: 1px solid $primary;
            border-radius: 5px;
            padding: 2px 10px;
            margin: 2px;
            .btn {
                text-decoration: none;
                outline: none;
                padding: 0;

                .glyphicon-remove {
                    transition: all 0.3s;
                    &:hover {
                        transform: rotate(90deg);
                        color: red;
                    }
                }
            }
        }
    }
}
