.multiSelect {
  .multiSelectItem:not(.multiSelectGroup).selected {
    background-color: $gray-lighter;
    background-image: none !important;
    border: none !important;
  }
  .clearButton {
    @extend .btn;
  }

  .inputFilter {
    @extend .form-control;
  }

  > button {
    @extend .form-control;

    .caret {
      top: 15px;
    }
  }

  .helperButton {
    @extend .btn;
  }
}

// on empêche la validation de colorer l'input de recherche
.has-error .multiSelect .inputFilter {
  border-color: $gray-light;
  box-shadow: none;
}