@import "config/globals";

$cardinals: "top",
"bottom",
"left",
"right";

$max: 30;

$colors: "white" $color-white,
"red" $color-red,
"blue" $color-blue,
"orange" $color-orange,
"green" $color-green,
"disabled" $color-disabled,
"lightgrey" $color-lightgrey,
"darkgrey" $color-darkgrey,
"black" $color-black,
"transparent" transparent,
"inherit" inherit;

$displays: "none",
"block",
"inline-block",
"inline";

#id-apps {
  /* top/bottom/left/right minus 5 -> +20*/
  @each $cardinal in $cardinals {
    @for $i from -5 through $max {
      @if $i < 0 {
        .#{$cardinal}-minus#{$i} {
          #{$cardinal}: #{$i}px !important;
        }
      } @else {
        .#{$cardinal}-#{$i} {
          #{$cardinal}: #{$i}px !important;
        }
      }
    }
  }

  /* min/max/width 5 -> 100 par paliers de 5 */
  @for $i from 1 through $max {
    .width-#{$i*5} {
      width: #{$i*5}px !important;
    }
    .min-width-#{$i*5} {
      min-width: #{$i*5}px !important;
    }
    .max-width-#{$i*5} {
      max-width: #{$i*5}px !important;
    }
  }


  /* width 1 -> 60 par paliers de 1*/
  @for $i from 1 through 100 {
    .width-#{$i}p {
      width: 1% * $i;
    }
  }

  /* min/max/width 125 -> 500 par paliers de 25 */
  @for $i from 5 through $max {
    $j: $i*25;
    .width-#{$j} {
      width: #{$j}px !important;
    }
    .min-width-#{$j} {
      min-width: #{$j}px !important;
    }
    .max-width-#{$j} {
      max-width: #{$j}px !important;
    }
  }

  /* min/max/height 5 -> 100 par paliers de 5 */
  @for $i from 1 through $max {
    .height-#{$i*5} {
      height: #{$i*5}px !important;
    }
    .min-height-#{$i*5} {
      min-height: #{$i*5}px !important;
    }
    .max-height-#{$i*5} {
      max-height: #{$i*5}px !important;
    }
  }

  /* min/max/height 125 -> 500 par paliers de 25 */
  @for $i from 5 through $max {
    $j: $i*25;
    .height-#{$j} {
      height: #{$j}px !important;
    }
    .min-height-#{$j} {
      min-height: #{$j}px !important;
    }
    .max-height-#{$j} {
      max-height: #{$j}px !important;
    }
  }

  /* padding-(top|bottom|left|right)-0 -> padding-20 */
  /* padding-0-1 -> padding-0-20 */
  /* padding-1-0 -> padding-20-0 */
  @for $i from 0 through $max {
    .padding-#{$i} {
      padding: #{$i}px !important;
    }
    @if $i != 0 {
      .padding-0-#{$i} {
        padding: 0 #{$i}px !important;
      }
      .padding-#{$i}-0 {
        padding: #{$i}px 0 !important;
      }
    }

    @each $cardinal in $cardinals {
      .padding-#{$cardinal}-#{$i} {
        padding-#{$cardinal}: #{$i}px !important;
      }
    }
  }

  /* padding-(top|bottom|left|right)-25 -> padding-100 par paliers de 5 */
  /* padding-0-25 -> padding-0-100 */
  /* padding-25-0 -> padding-100-0 */
  @for $i from 5 through $max {
    $j: $i*5;
    .padding-#{$j} {
      padding: #{$j}px !important;
    }
    .padding-0-#{$j} {
      padding: 0 #{$j}px !important;
    }
    .padding-#{$j}-0 {
      padding: #{$j}px 0 !important;
    }

    @each $cardinal in $cardinals {
      .padding-#{$cardinal}-#{$j} {
        padding-#{$cardinal}: #{$j}px !important;
      }
    }
  }

  /* margin-(top|bottom|left|right)-0 -> margin-20 */
  /* margin-0-1 -> margin-0-20 */
  /* margin-1-0 -> margin-20-0 */
  @for $i from 0 through $max {
    .margin-#{$i} {
      margin: #{$i}px !important;
    }
    @if $i != 0 {
      .margin-0-#{$i} {
        margin: 0 #{$i}px !important;
      }
      .margin-#{$i}-0 {
        margin: #{$i}px 0 !important;
      }
    }

    @each $cardinal in $cardinals {
      .margin-#{$cardinal}-#{$i} {
        margin-#{$cardinal}: #{$i}px !important;
      }
    }
  }

  /* margin-(top|bottom|left|right)-25 -> margin-100 par paliers de 5 */
  /* margin-0-25 -> margin-0-100 */
  /* margin-25-0 -> margin-100-0 */
  @for $i from 5 through $max {
    $j: $i*5;
    .margin-#{$j} {
      margin: #{$j}px !important;
    }
    .margin-0-#{$j} {
      margin: 0 #{$j}px !important;
    }
    .margin-#{$j}-0 {
      margin: #{$j}px 0 !important;
    }

    @each $cardinal in $cardinals {
      .margin-#{$cardinal}-#{$j} {
        margin-#{$cardinal}: #{$j}px !important;
      }
    }
  }

  /* border-(top|bottom|left|right)-0 */
  @each $cardinal in $cardinals {
    .border-#{$cardinal}-0 {
      border-#{$cardinal}: 0 !important;
    }
  }

  /* border-radius-0 -> 5 */
  @for $i from 0 through 5 {
    .border-radius-#{$i} {
      border-radius: #{$i}px !important;
    }
  }

  /* colors|background|border|hover */
  @each $color in $colors {
    .color-#{nth($color, 1)} {
      color: nth($color, 2) !important;
    }
    .background-#{nth($color, 1)} {
      background-color: nth($color, 2) !important;
    }
    .border-#{nth($color, 1)} {
      border-color: nth($color, 2) !important;
    }
    .hoverable-color-#{nth($color, 1)}:hover {
      color: nth($color, 2) !important;
    }
    @if nth($color, 2) != "inherit" {
      .hoverable-background-#{nth($color, 1)}:hover {
        background-color: lighten(nth($color, 2), 40%) !important;
      }
    }
    .hoverable-border-#{nth($color, 1)}:hover {
      border-color: nth($color, 2) !important;
    }
  }

  /* displays */
  @each $display in $displays {
    .display-#{$display} {
      display: #{$display};
    }
  }

  /* opacity */
  @for $i from 0 through 20 {
    $i: calc($i * 5);
    $j: calc($i / 100);
    @if $i > 5 and $i < 100 {
      .opacity-0#{$i} {
        opacity: #{$j} !important;
      }
    } @else if $i == 5 {
      .opacity-00#{$i} {
        opacity: #{$j} !important;
      }
    } @else if $i == 100 {
      .opacity-#{$i} {
        opacity: #{$j} !important;
      }
    }
  }

  /* font-size */
  @for $i from 0 through 30 {
    $i: calc($i * 5);
    $j: calc($i / 100);
    @if $i > 5 and $i < 100 {
      .font-size-0#{$i} {
        font-size: #{$j}em !important;
      }
    } @else if $i == 5 {
      .font-size-00#{$i} {
        font-size: #{$j}em !important;
      }
    } @else if $i >= 100 {
      .font-size-#{$i} {
        font-size: #{$j}em !important;
      }
    }
  }
}