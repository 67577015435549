#id-apps {
	.app-gestion {
		//
		// Variables
		// --------------------------------------------------
		@import 'colors';
		$brand-primary: $arcade_orange;
		$logo-url: '../img/MassiaGestion_129x50.png'; //
		// Theme
		// --------------------------------------------------
		@import 'variables';
		@import 'theme/theme';
		@import 'theme/components';
		@import 'theme/modules';

		// Component Massia
		@import '../../libs/massia-component/massia.component.scss';
		.dropdown-menu > li {
			&:hover {
				cursor: pointer;
			}
		}
		offre-proposition offre-producteur:nth-child(odd) > div {
			background: rgba(242, 148, 0, 0.3);
		}
		.title {
			color: $brand-primary;
			font-weight: 600;
		}
		.sub-title {
			color: $brand-primary;
		}
		.btn-outline {
			background-color: transparent;
			border-color: $brand-primary;
			transition: background-color 0.2s;
			&:hover {
				background-color: #f5af40;
				color: white;
				transition: background-color 0.2s;
			}
		}

		.proposition-head {
			padding: 6px 6px 0 6px;
			background-color: #e1f1ff;
			margin-bottom: 10px;
			border: solid 1px #cccccc;
		}
		.add-produit-form {
			padding: 10px;
			background-color: #f0f8ff;
		}
		.no-heading .panel-body {
			border: 0;
		}
		.form-horizontal .control-label {
			padding-top: 0px !important;
			line-height: 30px;
		}
		.form-group {
			margin-bottom: 2px;
		}
		.footer-container {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 55px;
			background-color: #f4f4f4;
			z-index: 100;
			border-color: #b7b7b7;
			padding: 0 15px;
			.grille-footer {
				width: 100%;
				padding-top: 12px;
				margin: 0;
				border-top: solid 1px #f29400;
				box-sizing: border-box;
				.row {
					margin-left: 0;
					margin-right: 0;
					> div {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
			label b {
				line-height: 34px;
			}
		}
		.sl-search-filter {
			.panel-heading {
				background-color: $brand-primary;
				opacity: 1;
				color: white;
			}
		}
		.sl-prefa-ticket-header {
			cursor: pointer;
		}
		.sl-truck-icon {
			color: black !important;
		}
		.panel-heading {
			padding: 4px 10px;
		}
		.newArticle {
			input {
				width: 100%;
			}
		}
		.btn {
			&.active {
				background-color: #f3b553;
				&:focus {
					background-color: #f3b553;
				}
			}
		}
		.liste-footer {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 55px;
			background-color: #f4f4f4;
			z-index: 100;
			border-color: #b7b7b7;
			padding: 0 15px;
		}
		.liste-footer-2 {
			width: 100%;
			padding-top: 12px;
			margin: 0;
			border-top: solid 1px #f29400;
			box-sizing: border-box;
		}
		.panel {
			background-color: transparent;
			margin-bottom: 0px !important;
		}

		.calendrier-fait {
			.btn-default {
				background-color: #86b919 !important;
				//border-radius: 28 !important;
				color: white;
				//border-collapse: separate !important;
				.active {
					background-color: #86b919 !important;
					//border-radius: 28 !important;
					color: white;
					//border-collapse: separate !important;
					text-decoration: underline;
				}
			}
		}

		.calendrier-hors-date {
			.btn-default {
				background-color: lightgray !important;
				//border-radius: 28 !important;
				color: black;
				//border-collapse: separate !important;
			}
			.active {
				background-color: lightgray !important;
				//border-radius: 28 !important;
				color: black;
				//border-collapse: separate !important;
			}
		}

		.calendrier-normal {
			.btn-default {
				background-color: white !important;
				//border-radius: 28 !important;
				color: black;
				//border-collapse: separate !important;
			}
		}

		.calendrier-encours {
			.btn-default {
				background-color: #f29400 !important;
				//border-radius: 28 !important;
				color: white;
				//border-collapse: separate !important;
			}
		}

		.calendrier-nonfait {
			.btn-default {
				background-color: #a1195b !important;
				//border-radius: 28px !important;
				color: white;
				//border-collapse: separate !important;
			}
		}

		.calendrier-cloture {
			.btn-default {
				background-color: black !important;
				//border-radius: 28 !important;
				color: white !important;
				//border-collapse: separate !important;
			}
		}

		.uib-daypicker {
			table {
				width: 100%;
			}
		}

		.uib-monthpicker {
			table {
				width: 100%;
			}
		}

		.uib-yearpicker {
			table {
				width: 100%;
			}
		}
	}

	.panel {
		background-color: transparent;
		margin-bottom: 0px !important;
	}
	.btn-default {
		&.active {
			background-color: #f3b553;
			&:focus {
				background-color: #f3b553;
			}
			&:hover {
				background-color: #f29400;
			}
		}
		[class^='massia-icon-'],
		[class*=' massia-icon-'] {
			&.darker:before {
				font-size: 18px;
				color: #4c4c4c;
			}
		}
		.tab-form-cdg {
			.panel {
				.col-md-5 {
					.col-md-7,
					.col-md-5 {
						padding: 0;
					}
				}
			}
		}
		.nav-tabs > li.active > a,
		.nav-tabs > li.active > a:hover,
		.nav-tabs > li.active > a:focus {
			color: #d38100;
		}
	}
	.tab-form-cdg {
		background-color: #f4f4f4; // background-color:#ff0000;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
		.panel-body {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
		.panel {
			margin-bottom: 20px !important;
			border: 1px solid #b7b7b7 !important;
		}
		.panel-group .panel-heading {
			background-color: #fff !important;
			opacity: 0.66;
			color: #000000;
		}
		.cdg-table {
			display: table;
			width: 100%;
			border-collapse: separate;
			border-spacing: 2px;
			.cdg-row {
				display: table-row;
				.cdg-cell-10 {
					display: table-cell;
					width: 10%;
					vertical-align: middle;
				}
				.cdg-cell-20 {
					display: table-cell;
					width: 10%;
					vertical-align: middle;
					&.bg {
						background-color: #ffdea8;
					}
					&.bg-alt {
						background-color: #ffcd7d;
					}
					&.pad {
						padding: 0 10px;
					}
				}
				.cdg-cell-20 {
					display: table-cell;
					width: 20%;
					vertical-align: middle;
					&.bg {
						background-color: #ffdea8;
					}
					&.bg-alt {
						background-color: #ffcd7d;
					}
					&.pad {
						padding: 0 10px;
					}
				}
				.cdg-cell-33 {
					display: table-cell;
					width: 33%;
					vertical-align: middle;
					&.bg {
						background-color: #ffdea8;
					}
					&.bg-alt {
						background-color: #ffcd7d;
					}
					&.pad {
						padding: 0 10px;
					}
				}
				.cdg-cell-40 {
					display: table-cell;
					width: 40%;
					vertical-align: middle;
					background-color: #ffdea8;
					&.bg {
						background-color: #ffdea8;
					}
					&.bg-alt {
						background-color: #ffcd7d;
					}
					&.pad {
						padding: 0 10px;
					}
				}
				.cdg-cell-45 {
					display: table-cell;
					width: 45%;
					vertical-align: middle;
					&.bg {
						background-color: #ffdea8;
					}
					&.bg-alt {
						background-color: #ffcd7d;
					}
					&.pad {
						padding: 0 10px;
					}
				}
				.cdg-cell-50 {
					display: table-cell;
					width: 50%;
					vertical-align: middle;
					&.bg {
						background-color: #ffdea8;
					}
					&.bg-alt {
						background-color: #ffcd7d;
					}
					&.pad {
						padding: 0 10px;
					}
				}
				.cdg-cell-60 {
					display: table-cell;
					width: 60%;
					vertical-align: middle;
					&.bg {
						background-color: #ffdea8;
					}
					&.bg-alt {
						background-color: #ffcd7d;
					}
					&.pad {
						padding: 0 10px;
					}
				}
				.cdg-cell-70 {
					display: table-cell;
					width: 70%;
					vertical-align: middle;
					&.bg {
						background-color: #ffdea8;
					}
					&.bg-alt {
						background-color: #ffcd7d;
					}
					&.pad {
						padding: 0 10px;
					}
				}
				.cdg-cell-80 {
					display: table-cell;
					width: 80%;
					vertical-align: middle;
					&.bg {
						background-color: #ffdea8;
					}
					&.bg-alt {
						background-color: #ffcd7d;
					}
					&.pad {
						padding: 0 10px;
					}
				}
				.cdg-cell-90 {
					display: table-cell;
					width: 80%;
					vertical-align: middle;
					&.bg {
						background-color: #ffdea8;
					}
					&.bg-alt {
						background-color: #ffcd7d;
					}
					&.pad {
						padding: 0 10px;
					}
				}
			}
		}
		select,
		input {
			min-width: 204px;
		}
		select.tva {
			min-width: 130px;
		}
		input[type='checkbox'] {
			min-width: 0;
			float: left !important;
			margin: 0 !important;
		}
		input {
			&.filtre {
				color: #000 !important;
				&::-webkit-input-placeholder {
					/* WebKit, Blink, Edge */
					color: #ccc;
				}
				&:-moz-placeholder {
					/* Mozilla Firefox 4 to 18 */
					color: #ccc;
					opacity: 1;
				}
				&::-moz-placeholder {
					/* Mozilla Firefox 19+ */
					color: #ccc;
					opacity: 1;
				}
				&:-ms-input-placeholder {
					/* Internet Explorer 10-11 */
					color: #ccc;
				}
				&::-ms-input-placeholder {
					/* Microsoft Edge */
					color: #ccc;
				}
			}
		}
		.multiSelect button {
			max-width: 450px;
		}
		.disabled-block {
			position: absolute;
			background: rgba(0, 0, 0, 0.6);
			display: block;
			top: -1px;
			width: 100%;
			height: 100%;
			z-index: 99;
			div:first-of-type {
				color: #fff;
				font-size: 35px;
				position: relative;
				top: 25%;
			}
		}
	}

	.facture-article .multiSelect button {
		max-width: 350px;
	}
	.form-control[disabled],
	#id-apps .multiSelect [disabled].inputFilter,
	#id-apps .multiSelect > button[disabled],
	fieldset[disabled] #id-apps .form-control,
	fieldset[disabled] #id-apps .multiSelect .inputFilter,
	fieldset[disabled] #id-apps .multiSelect > button {
		color: #aaa;
		background: #eee;
	}

	.form-disabled[disabled] {
		color: #aaa;
		background: #eee;
	}

	.form-disabled[disabled] label {
		color: #aaa;
	}
	.form-disabled[disabled] input,
	massia-swich,
	table {
		opacity: 0.8;
	}
	.commande-total {
		font-size: 20px;
	}

	// //tabs
	// #id-apps .nav-tabs > li.active > a,
	// #id-apps .nav-tabs > li.active > a:hover,
	// #id-apps .nav-tabs > li.active > a:focus{
	//     color:#d38100;
	// }
	.marge-bas {
		margin-bottom: 0px;
	}

	.color_bad {
		color: red;
	}

	.color_alert {
		color: fuchsia;
	}

	.color_white {
		color: #ffffff;
	}

	.champ-modifiable {
		// text-decoration:underline;
		// padding:4px;
		background-color: #f4f1b7;
		padding: 4px;
		cursor: pointer;
		border-radius: 5px;
	}
	.champ-modifiable:hover {
		padding: 2px;
		text-decoration: underline;
		border: solid 1px #fff;
	}

	.card > .form-group {
		margin: 0;
		margin-bottom: 6px;
	}

	.cdg-cell-ellipsis {
		cursor: pointer;
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
	}
}

.tab-style {
	.nav-tabs li a {
		color: #000 !important;
		background-color: lightgrey !important;
		transition: all 0.2s ease-in-out;
	}
	.nav-tabs > li.active > a,
	.nav-tabs > li.active > a:focus,
	.nav-tabs > li.active > a:hover {
		color: #fff !important;
		background-color: #f29400 !important;
		transition: all 0.2s ease-in-out;
	}
	transition: all 0.2s ease-in-out;
}

.error-popover {
	max-width: 1000px;
	min-width: 800px;
	z-index: 1 !important;
}

@media screen and (min-width: 768px) {
	.underline {
		&:after {
			content: '';
			position: absolute;
			width: 0;
			height: 3px;
			margin-top: 5px;
			left: 0;
			display: block;
			background: #f29400;
			transition: width 0.3s ease;
		}

		&:hover:after {
			width: 80%;
			left: 0;
			background: #f29400;
		}
	}

	.sub-menu-nav-item {
		position: relative;
		padding: 10px 20px;
	}
}

.massia-icon-delivery_truck {
	&.darker:before {
		color: #000 !important;
	}
}
