/****************
** ISTEVEN MULTI SELECT
****************/
#id-apps {
    .multiSelect {
        .checkboxLayer {
            max-height: 360px;
            overflow-y: auto;
            width: 100%;
            // right: 0;
        }
        /* Dans le cadre d'un single select */
        /* On met le caret à droite */
        > button {
            width: 100%;
            padding-right: 17px;
            text-align: left;
            //background-image: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .buttonLabel {
            display: inline;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        > button:hover {
            background-image: none;
            cursor: default;
        }

        > button {
            //outline-color: #66afe9 !important;
        }

        > button:focus {
            //box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
        }

        .caret {
            position: absolute;
            right: 10px;
            top: 10px;
        }

        .multiSelectItem {
            overflow: hidden;
            padding: 4px !important;
        }

        .multiSelectItem {
            min-width: 100%;
        }

        .multiSelectGroup span {
            font-weight: bold;
        }

        .multiSelectItem:hover,
        .multiSelectGroup:hover {
            //background-image: linear-gradient(#428BCA, #428BCA) !important;
            background-image: none;
            background-color: #428bca;
            cursor: pointer;
            border: 1px solid transparent;

            &:not(.selected) label {
                color: #fff;
            }
        }

        .tickMark {
            padding: 0 10px;
            background-color: #428bca;
            color: white;
            right: 0;
            top: 0;
            height: 30px;
            line-height: 30px;
        }
    }

    .bg-filter .multiSelect > button {
        padding-left: 8px;
    }

    .pointer-events-none .multiSelect button {
        color: #eee;
    }

    .pointer-events-none .multiSelect button {
        background-color: #eee;
        opacity: 1;
        //box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    }

    .has-error .multiSelect > button {
        border-color: #a94442;
        //-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        //box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    }

    .has-error .multiSelect label {
        color: #333;
    }

    .in-error > .multiSelect > button {
        border-color: #a94442;
        //-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }
    .in-error > .multiSelect > button:focus {
        border-color: #843534;
        //-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
        //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
        //outline: #843534;
    }

    .dropdown-menu-right .multiSelect .checkboxLayer {
        right: 0;
        left: auto;
    }
}
