$justify-content: (fstart: flex-start,
    fend: flex-end,
    center: center,
    end: end,
    start: start,
    left: left,
    right: right,
    normal: normal,
    space-b: space-between,
    space-a: space-around,
    space-e: space-evenly,
    space-c: center,
    stretch: stretch);

$align-item: (center: center,
    fstart: flex-start,
    fend: flex-end,
    stretch: stretch,
    baseline: baseline);

$margin-padding: 'top',
'right',
'bottom',
'left';
$margin-padding-shortcut: ('r': right,
    'l': left,
    't': top,
    'b': bottom);

$colors: (red: #b80606a1,
    yellow: #ffd000b0,
    blue: #00baff,
    green: #33b4336b,
    orange: rgba(248, 135, 6, 0.603),
    gray: #BFBFBF);

$height-width: ('': 1%,
    'px': 1px);

$positions: ('-pos-abs': absolute,
    '-pos-rel': relative);

$col-size: 12;
$map-col-props: ('': 0,
    '-sm': 576,
    '-md': 768,
    '-lg': 992,
    '-xl': 1200);

$grid-direction: ('-c': column,
    '-r': row);

$grid-pos: ('e': end,
    's': start);