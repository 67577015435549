.navbar {
  border: 0;

  li, .navbar-search-field {
    outline-color: transparent;
  }

  .navbar-search, [ui-sref], .dropdown-toggle {
    cursor: pointer;
  }

  .container-fluid {
    @media (min-width: #{$screen-md-min}) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-collapse {
    &.collapse {
      display: block;
      overflow: hidden;
      max-height: 0px;
      -webkit-transition: max-height .8s ease;
      -moz-transition: max-height .8s ease;
      -o-transition: max-height .8s ease;
      transition: max-height .8s ease;

      &.in {
        max-height: 2000px;
      }
    }
  }

  &-toggle {
    margin-top: 6px;
    margin-bottom: 5px;
    border-radius: 0;
  }

  .icon-bar {
    width: 25px;
    height: 3px;

    + .icon-bar {
      margin-top: 5px;
    }
  }

  li.navbar-search {
    @media (max-width: #{$screen-xs-max}) {
      display: none;
    }

    .ui-select-toggle {
      border-color: #fff !important;
    }

    > a {
      display: inline-block;
      vertical-align: top;
      height: 50px;
    }

    &.active {
      a:first-child {
        padding-right: 0;
        padding-top: 8px;
        padding-bottom: 8px;
      }
      a + a {
        border-left: 0;
      }
    }

    input,
    .ui-select-toggle {
      width: 180px !important;
      border: 0;
      border-bottom: 1px solid;
      padding-left: 2px;
      padding-right: 2px;
      padding-bottom: 2px;
      font-size: 14px;
      outline-color: transparent;
      border-radius: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: transparent;

      > .caret {
        display: none;
      }
    }

    .ui-select-match {
      outline-color: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  /*&.navbar-default {
    input,
    .ui-select-toggle {
      border-color: $navbar-default-color;
      color: $navbar-default-color;
      background-color: $navbar-default-bg;
    }

    .ui-select-placeholder,
    .ui-select-match-text {
      color: $gray-lighter;
    }

    .dropdown-header {
      background-color: $gray-lighter;
      border-bottom: 1px solid $gray-light;
      border-top: 1px solid $gray-light;
    }

    @media (max-width: #{$screen-xs-max}) {
      .dropdown-header {
        background-color: $gray-lighter;
      }
      .open .dropdown-menu>li>a {
        background-color: #fff;
        &:hover {
          background-color: $gray-lighter;
        }
      }
    }
  }*/

  .navbar-brand {
    height: 50px;
  }

  .navbar-brand,
  &-nav {
    margin-top: 0;
    margin-bottom: 0;


    .navbar-icon {
      font-size: 18px;
      padding-bottom: 2px;
      opacity: .6;

      & + *:not(.caret) {
        padding-right: 5px;
        padding-left: 10px;
      }
    }

    >li>a {
      font-size: 14px;
      padding: 15px 18px 15px 18px;
      line-height: 20px;
      max-height: 60px;

      > span {
        vertical-align: middle;
      }

      .caret {
        margin-left: 5px;
        margin-right: 5px;
        border-top: 6px dashed;
        border-top: 6px solid\9;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        opacity: .6;
      }
    }
  }

  .dropdown {
    &-menu {
      border-top: none;
      min-width: 250px;
    }

    .divider {
      margin: 0;
    }

    .dropdown-toggle {
      padding-right: 14px;
    }

    &-menu {
      padding-top: 0;
      padding-bottom: 0;
    }

    &-menu>li>a {
      padding: 8px 20px;
      font-size: 14px;
    }

    &-menu-item-collapse a {
      padding-left: 28px !important;
    }

    &-header {
      font-weight: bold;
      padding: 8px 10px;
      font-size: 14px;

      [class^="massia-icon-"], [class*=" massia-icon-"] {
        padding-right: 5px;
      }

      &-collapse {
        cursor: pointer;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }

    @media (max-width: #{$screen-xs-max}) {
      .caret {
        float: right;
        margin-top: 12px;
        margin-right: 12px;
      }
    }
  }
}

// TODO : modifier le fonctionnement du hide-header avec un menu/$broadcast et header/$on
// Pour future animation au moment de cacher le menu
// .animate-show-hide.ng-hide {
//   opacity: 0;
// }

// .animate-show-hide.ng-hide-add,
// .animate-show-hide.ng-hide-remove {
//   -webkit-transition: all ease 0.5s;
//   -o-transition: all ease 0.5s;
//   -moz-transition: all ease 0.5s;
//   transition: all ease 0.5s;
// }

.navbar-collapse-bar {
  text-align: center;
  font-size: 12px;
  line-height: 12px;

  hr {
    height: 2px;
    border: 0;
    margin: 0;
    background-color: $primary;
  }

  .btn {
    padding: 0px 12px;
    vertical-align: top;
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #fff;
    background-color: $primary;
    outline-color: transparent;
    line-height: 12px;
    opacity: .6;

    &:hover {
      opacity: 1;
    }
  }
}