@import "config/globals";

#id-apps {
  .rotate-45 {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .rotate-90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .rotate-270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  .flip-horizontal {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }

  .flip-vertical {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
  }

  .outline-none {
    outline: none !important;
  }

  .padding-3-10 {
    padding: 3px 10px !important;
  }

  .padding-10-3 {
    padding: 10px 13px !important;
  }

  .padding-5-10 {
    padding: 5px 10px !important;
  }

  .padding-10-5 {
    padding: 10px 5px !important;
  }

  .fit {
    max-width: 100%;
  }

  .fit-half {
    max-width: 50%;
  }

  .half-width {
    width: 50%
  }

  .full-width {
    width: 100%;
  }

  .half-height {
    height: 50%;
  }

  .full-height {
    height: 100%;
  }

  .pointer {
    cursor: pointer;
  }
  .dropdown-menu {
    .pointer:hover {
      cursor: pointer;
      background-color:#bbb;
    }
  }

  .cursor-default {
    cursor: default !important;
  }

  .cursor-move {
    cursor: move !important;
  }

  /* DEPRECATED */
  .color-grey {
    color: $color-darkgrey !important;
  }

  /* DEPRECATED */
  .background-grey {
    background-color: #ECECEC !important;
  }

  /* DEPRECATED */
  .hoverable-blue:hover {
    background: #E5F3FB !important;
  }

  .hoverable-bold:hover {
    font-weight: bold;
  }

  .table-hoverable-blue:hover td {
    background: #E5F3FB;
  }

  .vertical-divider {
    height: 20px;
    display: inline-block;
    float: left;
    border-left: 1px solid #ccc;
    border-right: 1px solid #F6F6F6;
  }

  .horizontal-divider {
    border-top: 2px solid #DBDBDB;
  }

  .font-small {
    font-size: 0.80em;
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 1px;
  }

  .text-ellipsis-universal {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .table-layout-fixed {
    width: 100%;
    table-layout: fixed;
  }

  .min-width-1200 {
    min-width: 1200px;
  }

  .scroll {
    overflow: auto !important;
  }

  .no-scroll {
    overflow: inherit !important;
    overflow-x: inherit !important;
    overflow-y: inherit !important;
  }

  .x-scroll {
    overflow-x: auto !important;
  }

  .y-scroll {
    overflow-y: auto !important;
  }

  .overflow-x-hidden {
    overflow-x: hidden;
  }

  .overflow-y-hidden {
    overflow-y: hidden;
  }

  .border-0 {
    border: 0 !important;
  }

  .text-underline,
  .text-underline:hover,
  .text-underline:active,
  .text-underline:focus {
    text-decoration: underline !important;
  }

  .font-style-italic {
    font-style: italic;
  }

  .font-weight-bold {
    font-weight: bold !important;
  }

  .font-weight-normal {
    font-weight: normal !important;
  }

  .vertical-align-top, .vertical-align-top td, .vertical-align-top th {
    vertical-align: top !important;
  }

  .vertical-align-middle, .vertical-align-middle td, .vertical-align-middle th {
    vertical-align: middle !important;
  }

  .vertical-align-bottom, .vertical-align-bottom td, .vertical-align-bottom th {
    vertical-align: bottom !important;
  }

  .vertical-align-sub, .vertical-align-sub td, .vertical-align-sub th {
    vertical-align: sub !important;
  }

  .position-fixed {
    position: fixed;
  }

  .position-relative {
    position: relative;
  }

  .position-absolute {
    position: absolute;
  }

  .fixed-top {
    top: 0 !important;
  }

  .border-green {
    border-color: green;
    border-width: 2px;
  }

  .list-style-type-none {
    list-style-type: none !important;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .pointer-events-none {
    pointer-events: none !important;
    background: #f5f5f5;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .square-box {
    width: 110px;
    height: 110px;
  }

  .word-wrap {
    word-wrap: break-word;
  }

  .no-border-after:after {
    border: none !important;
  }

  .no-border-before:before {
    border: none !important;
  }

  .no-shadow {
    box-shadow: none !important;
  }

  .resize-vertical {
    resize: vertical !important;
  }

  .border-none {
    border: none !important;
  }

  .top-dot {
    position: relative;
    &:after {
      content: '';
      border: 3px solid grey;
      position: absolute;
      border-radius: 10px;
      top: 1px;
      left: 1px;
      width: 5px;
      height: 5px;
    }

    &.top-dot-green:after {
      border-color: $color-limegreen;
    }

    &.top-dot-red:after {
      border-color: $color-red;
    }

    &.top-dot-blue:after {
      border-color: $color-blue;
    }

    &.top-dot-orange:after {
      border-color: $color-orange;
    }

    &.top-dot-right:after {
      right: 0;
      left: inherit;
    }
  }

  .btn-wrapper {
    display: inline-block;
  }
}