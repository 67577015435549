.ac-loading-wrapper-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.ac-loading-wrapper-top {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.ac-loading-wrapper-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
}
.ac-loading-wrapper-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}
.ac-loading-wrapper-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}