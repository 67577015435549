[ac-treeview] {
  .root-item .node-folder-icon {
    display: none;
  }

  .node-selected {
    border: 1px solid;
  }

  .ac-treeview-root-list ol ol {
    margin-left: 15px;
    padding-left: 4px !important;
    border-left: 1px solid;
  }
}