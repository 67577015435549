#app-wrap {
  // margin-bottom: $footer-height + 25px;
}

#app-footer {
  display: none;
  height: $footer-height;
  border-top: 5px solid $gray-light;
  text-align: center;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  // padding: 10px;
  width: 100%;
}