.panel {
  .panel-height {
    height: 200px;
  }

  .panel-height-200 {
    height: 200px;
  }

  .panel-height-150 {
    height: 150px;
  }

  .panel-title {
    > a,
    > small,
    > .small,
    > small > a,
    > .small > a {
      text-decoration: none;
    }
  }

  .panel-separator {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    width: 1px;
    margin: $panel-body-padding 0 $panel-body-padding -5px;
    border: 0;
    border-right: 1px solid;
  }

  .panel-heading {
    border-bottom: 0;

    .panel-heading-title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: $font-size-h3;
      line-height: $line-height-computed + 2 * ($padding-base-vertical + 1px);
    }

    .panel-heading-actions {
      float: right;
      position: relative;
    }
  }

  .panel-body {
    position: relative;

    h3 {
      font-size: $font-size-h4;
    }

    .table {
      margin-bottom: 0;
    }
  }

  .panel-footer {
    border-top: 0;
  }

  .panel-group {
    margin-bottom: 0;
  }

  .panel-primary .panel-heading-title {
    color: #ffffff;
  }
}

.panel-group {
  border-bottom: 1px solid;

  .panel {
    border-bottom: 0;

    &.panel-open .panel-heading .glyphicon-plus {
      @extend .glyphicon-chevron-down;
    }

    .panel-heading .glyphicon + span {
      margin-left: calc($padding-base-horizontal / 2);
    }

    + .panel {
      margin-top: 0;
    }
  }
}

.panel-default {
  .panel-heading + .panel-collapse > .panel-body {
    border-top: 0;
  }

  .panel-body {
    border-top: 1px solid;
    padding-left: 0;
    padding-right: 0;
    margin-left: $panel-body-padding;
    margin-right: $panel-body-padding;
  }

  .panel-footer {
    padding-left: 0;
    padding-right: 0;
    margin-left: $panel-body-padding;
    margin-right: $panel-body-padding;
  }
}

.panel-default .panel-body {
    border-top: 0;
}

.panel-sous-dispatching {
  padding: 10px;
}

.panel-block{
  border: solid 1px; 
  margin-bottom: 10px; 
  padding: 10px;
  background-color: #FFFFFF;
  border-color: #CCCCCC;
}

.panel-dispatching {
  border: solid 1px; 
  margin-bottom: 10px; 
  padding: 10px;
  border-color: #CCCCCC;
  background-color: #FFFFFF;
}

.panel-float-dispatching {
  border: solid 1px; 
  margin-bottom: 10px; 
  padding: 10px;
  border-color: #CCCCCC;
  background-color: #FFFFFF;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.panel-float-dispatching-utilise {
  border: solid 1px; 
  margin-bottom: 10px; 
  padding: 10px;
  border-color: #CCCCCC;
  background-color: #FFFFFF;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.panel-float-dispatching-complet {
  border: solid 1px; 
  margin-bottom: 10px; 
  padding: 10px;
  border-color: tomato;
  background-color: #FFFFFF;
}

.fixed-panel {
  min-height: 700px;
  max-height: 700px;
  overflow-y: scroll;
}

.panel-thin{
  div[role='tabpanel']{
    padding-left: 4px !important;
    padding-right: 4px !important;

    div[class='panel-body']{
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}

.panel-bleu .panel-heading {
  color: #31708f !important;
  background-color: #d9edf7 !important;
  border-color: #bce8f1 !important; 
}

.panel-bleu .panel-heading h3 {
  font-size: 18px !important;
}